import { Flex, List, Empty, Button, Divider } from 'antd';
import { useEffect, useContext, useState } from 'react';
import { GloablStateContext } from '@/context';


import './index.scss'
import { TEMPLATES_LIST } from '@/utils/constants';


export default function Templates () {
  const { isReady, setReady, object: activeObject, setActiveObject, editor } = useContext(GloablStateContext);
  const [layers, setLayers] = useState([]);

 
  const loadDemo = async (template) => {
    setReady(false);
    await editor.loadFromJSON(template);
    editor.fhistory.reset();
    setReady(true);
    setActiveObject(null);
    editor.fireCustomModifiedEvent();
  }



  return (
    <div
      className="gaist-panel-wrapper"
    >
      <div className="gaist-panel-wrapper-title">Templates</div>
      <div className="gaist-panel-wrapper-search">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15.2 16.34a7.5 7.5 0 1 1 1.38-1.45l4.2 4.2a1 1 0 1 1-1.42 1.41l-4.16-4.16zm-4.7.16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
        <input type='search' placeholder='Filter...'/>
      </div>
      <div className='templates'>
        {[...TEMPLATES_LIST].map((template,i)=>(
          <div  onClick={()=>loadDemo(template)} className='template' key={`template-${i}`} style={{
            backgroundImage : `URL(${template?.preview})`,
          }}/>
        ))}
      </div>
      {/* <Button onClick={(e)=>loadDemo(DEMOTEMP)}>Load DEMO</Button> */}
    </div>
  )
}