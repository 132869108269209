import fabric from 'fabric';
import Editor, { Scene } from '../';
import { LOAD_FROM_LOCAL_WHEN_INIT, AUTO_SAVE_WHEN_CHANGE } from '@/config';
import CREATION_BASE from '@/assets/creation.json'

export default class AutoSave {
  private canvas: fabric.Canvas;
  private editor: Editor;
  private saving: boolean;
  private canSave: boolean;


  constructor (editor) {
    this.canvas = editor.canvas;
    this.editor = editor;    
    this.saving = false;
    this.canSave = true;

    this.init();
  }

  private init () {
    if (AUTO_SAVE_WHEN_CHANGE) {
      this.canvas.on(this.initAutoSaveEvents());
    }
  }

  public dispose () {
    if (AUTO_SAVE_WHEN_CHANGE) {
      this.canvas.off(this.initAutoSaveEvents());
    }
  }

  public setCanSave (can) {
    this.canSave = can;
  }

  private autoSaveAction () {
    if (this.saving) return;
    this.saving = true;

    try {
      if (this.canSave) {
        const currentSceneUpdated = this.editor.canvas2Json()
        console.log("currentSceneUpdated",currentSceneUpdated)
        const updatedCreation = this.editor.creation?.map((c)=>{
          if(c?.position == this.editor.currentIndex){
            return {
              ...currentSceneUpdated,
              position : this.editor.currentIndex
            }
          }
          return c
        })
        localStorage.setItem('fabritor_web_json', JSON.stringify(updatedCreation));
        this.editor.creation = updatedCreation as Scene[]
        
      }
    } catch(e) {  console.log(e) }

    this.saving = false;
  }

  private _getJSON () {
    return this.editor.canvas2Json();
  }

  private initAutoSaveEvents () {
    return {
      'object:added': this.autoSaveAction.bind(this),
      'object:removed': this.autoSaveAction.bind(this),
      'object:modified': this.autoSaveAction.bind(this),
      'object:skewing': this.autoSaveAction.bind(this),
      'fabritor:object:modified': this.autoSaveAction.bind(this)
    };
  }

  public async loadFromLocal () {
    if (LOAD_FROM_LOCAL_WHEN_INIT) {
      try {
        var jsonStr = localStorage.getItem('fabritor_web_json')
        if(!jsonStr){
          jsonStr = JSON.stringify(CREATION_BASE)
        }
        if (jsonStr) {
          const json = JSON.parse(jsonStr) as Scene[];
          if(json){
            this.editor.creation = json
          }
          if(json?.length > 0 && this.editor.currentIndex >0){
            // console.log("loadFromLocal",this.editor.currentIndex,json)
            const currentScene = json[this.editor.currentIndex - 1]
            if(currentScene){
              await this.editor.loadFromJSON(currentScene);
            }
          }
        }
      } catch(e) {  console.log(e) }
    }
  }

  public async loadFromJSON (json) {
    if (json) {
      try {
        await this.editor.loadFromJSON(json);
        
      } catch(e) {  console.log(e) }
    }
  }
}