import React from 'react'

type Props = {
    style : any
}

export default function ScenesIcons({style }: Props) {
    return (
        <svg 
            style={{...style}} 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 96 96" width="18" height="18" 
            fill='currentColor'
        >
            <defs><clipPath id="__lottie_element_7"><rect width="96" height="96" x="0" y="0"></rect></clipPath><clipPath id="__lottie_element_12"><path fill="#ffffff" clipRule="nonzero" d=" M4.741000175476074,2.492000102996826 C3.2219998836517334,2.492000102996826 1.9910000562667847,3.7230000495910645 1.9910000562667847,5.242000102996826 C1.9910000562667847,5.242000102996826 1.9910000562667847,18.742000579833984 1.9910000562667847,18.742000579833984 C1.9910000562667847,20.26099967956543 3.2219998836517334,21.492000579833984 4.741000175476074,21.492000579833984 C4.741000175476074,21.492000579833984 12.241000175476074,21.492000579833984 12.241000175476074,21.492000579833984 C13.760000228881836,21.492000579833984 14.991000175476074,20.26099967956543 14.991000175476074,18.742000579833984 C14.991000175476074,18.742000579833984 14.991000175476074,5.242000102996826 14.991000175476074,5.242000102996826 C14.991000175476074,3.7230000495910645 13.760000228881836,2.492000102996826 12.241000175476074,2.492000102996826 C12.241000175476074,2.492000102996826 4.741000175476074,2.492000102996826 4.741000175476074,2.492000102996826" fillOpacity="1"></path></clipPath></defs>
            <g clipPath="url(#__lottie_element_7)" ><g clipPath="url(#__lottie_element_12)" style={{display: "block"}} transform="matrix(4,0,0,4,0,0)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,8.5,12)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="currentColor" strokeOpacity="0.9500000000000001" strokeWidth="3" d=" M-3.75,-9.5 C-5.269000053405762,-9.5 -6.5,-8.269000053405762 -6.5,-6.75 C-6.5,-6.75 -6.5,6.75 -6.5,6.75 C-6.5,8.269000053405762 -5.269000053405762,9.5 -3.75,9.5 C-3.75,9.5 3.75,9.5 3.75,9.5 C5.269000053405762,9.5 6.5,8.269000053405762 6.5,6.75 C6.5,6.75 6.5,-6.75 6.5,-6.75 C6.5,-8.269000053405762 5.269000053405762,-9.5 3.75,-9.5 C3.75,-9.5 -3.75,-9.5 -3.75,-9.5z"></path></g></g>
            <g style={{display: "block"}} transform="matrix(4,0,0,4,0,0)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,19.899999618530273,12)"><path fill="currentColor" fillOpacity="0.9500000000000001" d=" M1.100000023841858,-4.935999870300293 C1.100000023841858,-4.935999870300293 2.9000000953674316,-3.8959999084472656 2.9000000953674316,-3.8959999084472656 C2.9000000953674316,-3.8959999084472656 2.9000000953674316,3.8959999084472656 2.9000000953674316,3.8959999084472656 C2.9000000953674316,3.8959999084472656 1.100000023841858,4.935999870300293 1.100000023841858,4.935999870300293 C1.100000023841858,4.935999870300293 1.100000023841858,-4.935999870300293 1.100000023841858,-4.935999870300293z M-2.9000000953674316,-7.244999885559082 C-2.9000000953674316,-7.244999885559082 -0.8999999761581421,-6.090000152587891 -0.8999999761581421,-6.090000152587891 C-0.8999999761581421,-6.090000152587891 -0.8999999761581421,6.090000152587891 -0.8999999761581421,6.090000152587891 C-0.8999999761581421,6.090000152587891 -2.9000000953674316,7.244999885559082 -2.9000000953674316,7.244999885559082 C-2.9000000953674316,7.244999885559082 -2.9000000953674316,-7.244999885559082 -2.9000000953674316,-7.244999885559082z"></path></g>
            </g></g>
        </svg>
    )
}

export  function DurationIcons({style }: Props) {
    return (
        <svg 
            style={{...style}} 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" width="18" height="18" 
            fill='none'
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M12 6V12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M16.24 16.24L12 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g>
        </svg>
    )
}