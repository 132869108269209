import { Layout, Tabs, Flex, FloatButton } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AlertOutlined, FontSizeOutlined, PictureOutlined, BorderOutlined, BulbOutlined, AppstoreOutlined, GithubFilled } from '@ant-design/icons';
import TextPanel from './TextPanel';
import ImagePanel from './ImagePanel';
import ShapePanel from './ShapePanel';
import PaintPanel from './PaintPanel';
import DesignPanel from './DesignPanel';
import { GloablStateContext } from '@/context';
import AppPanel from './AppPanel';
import { PANEL_WIDTH } from '@/config';

import './index.scss';
import Templates from './Templates';
import TemplatesIcons from '@/components/Icons/templates';
import TypographyIcons from '@/components/Icons/typography';
import GaistAI from './GaistAI';
import Setter from '../setter';

const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
  position: 'relative',
  backgroundColor: '#fff',
  borderRight: '1px solid var(--border-primary)',
  minWidth : 'unset',
  maxWidth : `${PANEL_WIDTH}px`,
  flex: 'unset'
};

const iconStyle = { fontSize: 18, marginRight: 0 };

const OBJECT_TYPES = [
  // {
  //   label: 'Assistant',
  //   value: 'gaist-ai',
  //   icon : <svg width={24} height={24} style={iconStyle} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M9.6 5.613C7.91 5.466 6.98 4.874 6.484 3.7c-.179-.423-.304-.917-.384-1.5 0-.1-.1-.2-.2-.2s-.2.1-.2.2c-.08.583-.205 1.077-.384 1.5C4.821 4.874 3.891 5.466 2.2 5.613c-.1 0-.2.1-.2.2s.1.2.2.2c2.1.4 3.2 1.187 3.5 3.387 0 .1.1.2.2.2s.2-.1.2-.2c.3-2.2 1.4-2.987 3.5-3.387.1 0 .2-.1.2-.2s-.1-.2-.2-.2Z" fill="currentColor"></path><path d="M19.469 11.865c-4-.8-5.726-2.73-6.526-6.629a.493.493 0 0 0-.474-.371.493.493 0 0 0-.475.376c-.009.006.007-.015 0 0-.8 4-2.625 5.824-6.525 6.624a.5.5 0 0 0 0 1c4 .8 5.717 2.687 6.517 6.587a.493.493 0 0 0 .483.413.493.493 0 0 0 .477-.387c-.005.01.006-.008 0 0 .8-4 2.623-5.813 6.523-6.613a.5.5 0 0 0 0-1Z" fill="currentColor"></path><path d="M21.465 5.8c0-.084-.061-.14-.144-.156l-.056-.013c-1.168-.305-1.876-1.024-2.073-2.108a.153.153 0 0 0-.153-.153v.004c-.084 0-.14.062-.156.144l-.013.056c-.305 1.168-1.024 1.876-2.108 2.073a.153.153 0 0 0-.153.153h.004c0 .084.062.14.145.156l.055.013c1.168.305 1.876 1.024 2.073 2.108 0 .084.069.153.153.153v-.004c.084 0 .14-.062.156-.145l.014-.055c.304-1.168 1.023-1.876 2.107-2.073a.15.15 0 0 0 .15-.153Z" fill="currentColor"></path><path d="M7.919 18.715c-1-.3-1.582-.782-1.782-1.782a.218.218 0 1 0-.436 0c-.3 1-.782 1.582-1.782 1.782a.218.218 0 0 0 0 .436c1 .3 1.582.782 1.782 1.782a.218.218 0 0 0 .436 0c.3-1 .782-1.582 1.782-1.782a.218.218 0 0 0 0-.436Z" fill="currentColor"></path></svg>
  // },
  {
    label: 'Designs',
    value: 'designs',
    icon: <TemplatesIcons style={iconStyle} />
  },
  {
    label: 'Text',
    value: 'text',
    icon: <TypographyIcons style={iconStyle} />
  },
  {
    label: 'Medias',
    value: 'media',
    icon: <PictureOutlined style={iconStyle} />
  },
  {
    label: 'Elements',
    value: 'shape',
    icon: <BorderOutlined style={iconStyle} />
  },
  // {
  //   label: 'Paints',
  //   value: 'paint',
  //   icon: <BulbOutlined style={iconStyle} />
  // },
  // {
  //   label: 'Apps',
  //   value: 'app',
  //   icon: <AppstoreOutlined style={iconStyle} />
  // },
  {
    label: 'History',
    value: 'used',
    icon: <AlertOutlined style={iconStyle} />
  },
  {
    label: 'Customize',
    value: 'pannel',
    icon: <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect x="0" fill="none" width="24" height="24"></rect> <g> <path d="M2 6c0-1.505.78-3.08 2-4 0 .845.69 2 2 2 1.657 0 3 1.343 3 3 0 .386-.08.752-.212 1.09.74.594 1.476 1.19 2.19 1.81L8.9 11.98c-.62-.716-1.214-1.454-1.807-2.192C6.753 9.92 6.387 10 6 10c-2.21 0-4-1.79-4-4zm12.152 6.848l1.34-1.34c.607.304 1.283.492 2.008.492 2.485 0 4.5-2.015 4.5-4.5 0-.725-.188-1.4-.493-2.007L18 9l-2-2 3.507-3.507C18.9 3.188 18.225 3 17.5 3 15.015 3 13 5.015 13 7.5c0 .725.188 1.4.493 2.007L3 20l2 2 6.848-6.848c1.885 1.928 3.874 3.753 5.977 5.45l1.425 1.148 1.5-1.5-1.15-1.425c-1.695-2.103-3.52-4.092-5.448-5.977z"></path> </g></g></svg>
  },
];

export default function Panel () {
  const [currentTab,setCurrentTab] = useState("designs")
  const { editor ,object, setActiveObject, } = useContext(GloablStateContext);

  const renderPanel = (value) => {
    // if (value === 'gaist-ai') {
    //   return <GaistAI />;
    // }
    if (value === 'designs') {
      return <Templates />;
    }
    if (value === 'used') {
      return <DesignPanel />;
    }
    if (value === 'text') {
      return <TextPanel />;
    }
    if (value === 'media') {
      return <ImagePanel />;
    }
    if (value === 'shape') {
      return <ShapePanel />;
    }
    if (value === 'paint') {
      return <PaintPanel />;
    }
    if (value === 'app') {
      return <AppPanel />;
    }
    if (value === 'pannel') {
      return <Setter />;
    }
    return null;
  }

  useEffect(()=>{
    if(object &&  [
      'textbox',
      'f-text',
      'circle',
      'triangle',
      'rect',
      'polygon',
      'ellipse',
      'f-line',
      'f-arrow',
      'f-tri-arrow',
      'f-image',
      'f-video',
      'path',
      'group',
      'activeSelection'
    ]?.includes(object?.type)){

      handleTabChange('pannel')
    }else if(!object && currentTab=="pannel"){
      setCurrentTab("gaist-ai")
    }
    console.log("object",object)
  },[object])

  const renderLabel = (item) => {
    return (
      <Flex className='aside-icon' vertical justify="center">
        <div >{item.icon}</div>
        <div className='aside-icon-label'>{item.label}</div>
      </Flex>
    )
  }

  const handleTabChange = (k) => {
    setCurrentTab(k)
    if(k!="pannel"){
      setActiveObject(null)
    }
    if (editor?.canvas) {
      if (k === 'paint') {
        editor.canvas.isDrawingMode = true;
      } else {
        editor.canvas.isDrawingMode = false;
      }
    }
  }

  const handleChangeSider = () =>{
    if(currentTab==null){
      setCurrentTab("gaist-ai")
    }else{
      setCurrentTab(null)
    }
  }

  return (
    <Sider
      style={siderStyle}
      // width={PANEL_WIDTH}
      className="gaist-sider"
    >
      <Tabs
        tabPosition="left"
        style={{ flex: 1, overflow: 'auto', color : "var(--aside-txt-color)" }}
        size="small"
        onChange={handleTabChange}
        popupClassName='gaist-sider-content'
        activeKey={currentTab}
        items={
          OBJECT_TYPES.map((item) => {
            return {
              label: renderLabel(item),
              key: item.value,
              children: renderPanel(item.value)
            };
          })
        }
      />
      <div className='gaist-sider-closer' onClick={(e) => { handleChangeSider() }}>
        <div className='gaist-sider-closer-content'>
          <svg className='gaist-sider-closer-bg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 16" width="96" height="16" fill="none">
            <path stroke='rgb(232, 232, 232)' d="M0 16v-3c20 0 12-12 32-12h32c20 0 12 12 32 12"></path>            
            <path fill='#000'  d="M0 13.5c20 0 12-12 32-12h32c20 0 12 12 32 12"></path>
          </svg>
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 96" width="16" height="96" fill="none" className='gaist-sider-closer-bg' >
            <path stroke='rgb(232, 232, 232)' d="M0,0 h1 c0,20,12,12,12,32 v32 c0,20,-12,12,-12,32 H0 z"></path>
            <path stroke='rgb(232, 232, 232)' d="M0.5,0 c0,20,12,12,12,32 v32 c0,20,-12,12,-12,32"></path>
          </svg> */}
          {(currentTab!=null) ? (
            <svg className='gaist-sider-closer-ic' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path fill="none" stroke="#ccc" strokeLinecap="round" strokeWidth="4" d="M3.17 5 5.3 7.12a1 1 0 0 0 1.42 0L8.83 5"></path>
            </svg>

          ) : (
            <svg className='gaist-sider-closer-ic' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path fill="none" stroke="#ccc" strokeLinecap="round" strokeWidth="4" d="M8.83 7 6.7 4.88a1 1 0 0 0-1.42 0L3.17 7"></path>
            </svg>

          )}
        </div>
      </div>
    </Sider>
  )
}