export const APP_NAME = 'gaist';
export const APP_VERSION = '3.0.0';
export const SCHEMA_VERSION = 3;
export const SCHEMA_VERSION_KEY = 'fabritor_schema_version';
export const LOG_PREFIX = `${APP_NAME}_log：`;
import template1 from '@/assets/templates/template1.json'
import template2 from '@/assets/templates/template2.json'
import template3 from '@/assets/templates/template3.json'


export const TEMPLATES_LIST = [
    {
      ...template1,
      preview : "https://promethee-assets.s3.uk.io.cloud.ovh.net/medias/asset/5c0a55eb-874e-44b3-aa3b-f531a2167df6/template2.png"
    },
    {
      ...template2,
      preview : "https://promethee-assets.s3.uk.io.cloud.ovh.net/medias/asset/b086bd23-c420-4dd5-b6b6-f51779839f23/template1.png"
    },
    {
      ...template3,
      preview : "https://promethee-assets.s3.uk.io.cloud.ovh.net/medias/asset/b07cccc7-50bc-4780-8483-4d558595933a/template3.png"
    }
]
export const OBJECT_DEFAULT_CONFIG = {
  // controls
  borderColor: '#4096ff',
  borderScaleFactor: 2,
  cornerStrokeColor: '#2222',
  cornerColor: '#4096ff',
  cornerSize: 12,
  cornerStyle: 'circle',
  transparentCorners: false,
  padding: 0,
  centeredScaling: false,
  strokeUniform: true,
  paintFirst: 'stroke'
}

export const TEXTBOX_DEFAULT_CONFIG = {
  // styles
  fill: '#000000',
  fontWeight: 'normal',
  fontSize: 50,
  lineHeight: 1.30,
  textAlign: 'center',
  fontFamily: 'AlibabaPuHuiTi',
  // size
  width: 500,
  // 中文处理
  splitByGrapheme: true
}

export const FONT_PRESET_FAMILY_LIST = [
    { 
      label: <span style={{ fontFamily: 'Abril Fatface', fontSize: 16 }} title="Abril Fatface">Abril Fatface</span>, 
      value: 'Abril Fatface' 
    },
    { 
      label: <span style={{ fontFamily: 'Anton', fontSize: 16 }} title="Anton">Anton</span>, 
      value: 'Anton' 
    },
    { 
      label: <span style={{ fontFamily: 'Bodoni Moda SC', fontSize: 16 }} title="Bodoni Moda">Bodoni Moda</span>, 
      value: 'Bodoni Moda SC' 
    },
    { 
      label: <span style={{ fontFamily: 'Oswald', fontSize: 16 }} title="Oswald">Oswald</span>, 
      value: 'Oswald' 
    },
    { 
      label: <span style={{ fontFamily: 'Pinyon Script', fontSize: 16 }} title="Pinyon Script">Pinyon Script</span>, 
      value: 'Pinyon Script' 
    },
    { 
      label: <span style={{ fontFamily: 'Playwrite MX', fontSize: 16 }} title="Playwrite MX">Playwrite MX</span>, 
      value: 'Playwrite MX' 
    },
    { 
      label: <span style={{ fontFamily: 'Roboto', fontSize: 16 }} title="Roboto">Roboto</span>, 
      value: 'Roboto' 
    },
    { 
      label: <span style={{ fontFamily: 'SmileySans', fontSize: 16 }} title="Smiley Sans">Smiley Sans</span>, 
      value: 'SmileySans' 
    },
  // { 
  //   label: <span style={{ fontFamily: 'SmileySans', fontSize: 16 }}>得意黑</span>, 
  //   value: 'SmileySans' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '霞鹜新晰黑', fontSize: 16 }}>霞鹜新晰黑</span>, 
  //   value: '霞鹜新晰黑' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '霞鹜文楷', fontSize: 16 }}>霞鹜文楷</span>, 
  //   value: '霞鹜文楷' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '小赖字体', fontSize: 16 }}>小赖字体</span>, 
  //   value: '小赖字体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '悠哉字体', fontSize: 16 }}>悠哉字体</span>, 
  //   value: '悠哉字体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: 'AlibabaPuHuiTi', fontSize: 16 }}>阿里巴巴普惠体</span>, 
  //   value: 'AlibabaPuHuiTi'
  // },
  // { 
  //   label: <span style={{ fontFamily: '霞鹜尚智黑', fontSize: 16 }}>霞鹜尚智黑</span>, 
  //   value: '霞鹜尚智黑' 
  // },
  // { 
  //   label: <span style={{ fontFamily: 'SourceHanSans', fontSize: 16 }}>思源黑体</span>, 
  //   value: 'SourceHanSans' 
  // },
  // { 
  //   label: <span style={{ fontFamily: 'SourceHanSerif', fontSize: 16 }}>思源宋体</span>, 
  //   value: 'SourceHanSerif' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '方正楷体', fontSize: 16 }}>方正楷体</span>, 
  //   value: '方正楷体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '包图小白体', fontSize: 16 }}>包图小白体</span>, 
  //   value: '包图小白体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '手写杂字体', fontSize: 16 }}>手写杂字体</span>, 
  //   value: '手写杂字体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '胡晓波男神体', fontSize: 16 }}>胡晓波男神体</span>, 
  //   value: '胡晓波男神体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '胡晓波骚包体', fontSize: 16 }}>胡晓波骚包体</span>, 
  //   value: '胡晓波骚包体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '站酷快乐体', fontSize: 16 }}>站酷快乐体</span>, 
  //   value: '站酷快乐体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '站酷文艺体', fontSize: 16 }}>站酷文艺体</span>, 
  //   value: '站酷文艺体' 
  // },
  // { 
  //   label: <span style={{ fontFamily: '站酷小薇LOGO体', fontSize: 16 }}>站酷小薇LOGO体</span>, 
  //   value: '站酷小薇LOGO体' 
  // }
]

export const SKETCH_ID = 'gaist-sketch';

export const FABRITOR_CUSTOM_PROPS = [
  'id',
  'fabritor_desc',
  'selectable',
  'hasControls',
  'sub_type',
  'imageSource',
  'imageBorder',
  'oldArrowInfo'
];