import { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { GloablStateContext } from '@/context';
import { DRAG_ICON } from '@/assets/icon';
import { ClearOutlined, DragOutlined, ExclamationCircleFilled, UndoOutlined, RedoOutlined } from '@ant-design/icons';
import { CenterV } from '@/fabritor/components/Center';
import ToolbarItem from './ToolbarItem';
import ToolbarDivider from '@/fabritor/components/ToolbarDivider';

import './index.scss';

export default function Toolbar () {
  const { setActiveObject, editor } = useContext(GloablStateContext);
  const [panEnable, setPanEnable] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  const clearCanvas = () => {
    Modal.confirm({
      title: 'Confirm clearing the canvas and clearing the history at the same time?',
      icon: <ExclamationCircleFilled />,
      async onOk () {
        await editor.clearCanvas();
        setActiveObject(editor.sketch);
        editor.fireCustomModifiedEvent();
      },
      okText: 'Confirm',
      cancelText: 'Cancel'
    });
  }

  const enablePan = () => {
    const enable = editor.switchEnablePan();
    setPanEnable(enable);
  }

  useEffect(() => {
    if (editor) {
      setCanUndo(editor.fhistory.canUndo());
      setCanRedo(editor.fhistory.canRedo());
    }
  });

  return (
    <CenterV gap={4} style={{ paddingRight: 12 }}>
      <ToolbarItem disabled={!canUndo} title="undo" onClick={() => { editor.fhistory.undo() }}>
        <UndoOutlined style={{ fontSize: 20 }} />
      </ToolbarItem>
      <ToolbarItem disabled={!canRedo} title="redo" onClick={() => { editor.fhistory.redo() }}>
        <RedoOutlined style={{ fontSize: 20 }} />
      </ToolbarItem>
      <ToolbarDivider />
      <ToolbarItem onClick={enablePan} title={panEnable ? 'Select Element' : 'Drag & Drop Canvas'}>
        {
          panEnable? 
          <DragOutlined style={{ fontSize: 22, color: panEnable ? '#var(--header-text-color)' : '#ccc' }} /> :
          <img className='header-drag-icon' src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(DRAG_ICON)}`} style={{ width: 22, height: 22, color:"var(--header-text-color)", fill: "var(--header-text-color)" }} />
        }
      </ToolbarItem>
      <ToolbarItem onClick={clearCanvas} title="Empty canvas">
        <ClearOutlined style={{ fontSize: 20, color:"var(--header-text-color)", fill: "var(--header-text-color)" }} />
      </ToolbarItem>
    </CenterV>
  )
}