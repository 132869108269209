import { createFTextClass } from './FText';
import { createFImageClass } from './FImage';
import { createFLineClass } from './FLine';
import { createFArrowClass, createFTriArrowClass } from './FArrow';
import { createFVideoClass } from './FVideo';

export default function () {
  createFTextClass();
  createFImageClass();
  createFVideoClass();
  createFLineClass();
  createFArrowClass();
  createFTriArrowClass();
}