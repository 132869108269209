import React, { useContext, useState } from 'react'
import { GloablStateContext } from '@/context';
import Slide from '@/components/Slide';
import { Button } from 'antd';

type Props = {}

export default function BaseTimeline({ }: Props) {

    const { isReady, setReady, object: activeObject, setActiveObject, editor } = useContext(GloablStateContext);

    const [layers, setLayers] = useState([]);

    // console.log("Widget top",editor?.creation)


    const loadDemo = async (position: number) => {
        editor.updateCurrentIndex(position)
        const currentScene = editor.creation?.find((p: any) => { return p?.position === position })
        setReady(false);
        await editor.loadFromJSON(currentScene);
        editor.fhistory.reset();
        setReady(true);
        setActiveObject(null);
        editor.fireCustomModifiedEvent();
    }
    return (
        <div className='editor-timeline-box'>
            {editor?.creation?.map((template: any, i) => (
                <div key={`scene-${i}`} className={`editor-timeline-box-item ${template.position == editor?.currentIndex ? "active-border" : ""}`} onClick={(e) => { }}>
                    <div className='editor-timeline-box-item-content'>
                        {/* <Slide content={template} onClick={() => loadDemo(template?.position)} template={false} /> */}
                        <Button onClick={() => loadDemo(template?.position)} >Slide</Button>
                        <div className='editor-timeline-box-item-content-overlay'></div>
                    </div>
                </div>
            ))}
        </div>
    )
}