import { Button, Flex } from 'antd';
import { useState } from 'react';
import { BorderOutlined } from '@ant-design/icons'
import MoreConfigWrapper from '../Form/MoreConfigWrapper';
import CommonBorderSetter from '../BorderSetter';

export default function BorderSetter (props) {
  const { value, onChange } = props;
  const [showMore, setShowMore] = useState(false);

  return (
    <div style={{
      display : "flex",
      flexDirection : "column"
    }} >
      <Button  icon={<BorderOutlined />} onClick={() => { setShowMore(true) }}>Border</Button>
      <MoreConfigWrapper
        open={showMore}
        setOpen={setShowMore}
        title="Border"
      >
        <div style={{ marginTop: 24 }}>
          <CommonBorderSetter value={value} onChange={onChange} />
        </div>
      </MoreConfigWrapper>
    </div>
  )
}