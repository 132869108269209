import { useContext, useEffect, useState } from 'react';
import { GloablStateContext } from '@/context';
import {CiCircleFilled, LockOutlined, UnlockOutlined, CopyOutlined, DeleteOutlined, PicCenterOutlined, AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined, VerticalAlignTopOutlined, VerticalAlignMiddleOutlined, VerticalAlignBottomOutlined, RightOutlined, FunctionOutlined } from '@ant-design/icons';
import { SKETCH_ID } from '@/utils/constants';
import OpacitySetter from './OpacitySetter';
import ToolbarItem from '../../header/Toolbar/ToolbarItem';
import { CenterV } from '@/fabritor/components/Center';
import { copyObject, pasteObject, removeObject } from '@/utils/helper';
import FlipSetter from './FlipSetter';
import { Divider } from 'antd';
import Animate from './Animate';
import MoreConfigWrapper from '../Form/MoreConfigWrapper';
import ImageFx from '../ImageSetter/ImageFx';
import FList from '@/fabritor/components/FList';
import './index.scss'

const ALIGH_TYPES = [
  {
    label: 'Center',
    icon: PicCenterOutlined,
    key: 'center'
  },
  {
    label: 'Left',
    icon: AlignLeftOutlined,
    key: 'left'
  },
  {
    label: 'Horizontal centering',
    icon: AlignCenterOutlined,
    key: 'centerH'
  },
  {
    label: 'Right',
    icon: AlignRightOutlined,
    key: 'right'
  },
  {
    label: 'Top',
    icon: VerticalAlignTopOutlined,
    key: 'top'
  },
  {
    label: 'Vertical centering',
    icon: VerticalAlignMiddleOutlined,
    key: 'centerV'
  },
  {
    label: 'Bottom',
    icon: VerticalAlignBottomOutlined,
    key: 'bottom'
  }
]

export default function CommonSetter() {
  const { object, editor } = useContext(GloablStateContext);
  const [lock, setLock] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [showAnimation, setShowAnimations] = useState(false)

  const IMAGE_ANIMATIONS_CONFIG = [
    {
      icon: <svg fill="currentColor" width={24} height={24} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>animation_line</title> <g id="b9dc111b-c0fc-4dae-9c72-b1a6d11e341d" data-name="Layer 3"> <path d="M10.16,31.71a4.4,4.4,0,0,1-4.64-1A4.34,4.34,0,0,1,4.23,27.6a4.41,4.41,0,0,1,.18-1.2,11.61,11.61,0,0,1-1-2.56,6.4,6.4,0,0,0,9.33,8.63A11.55,11.55,0,0,1,10.16,31.71Z"></path> <path d="M18.41,27.68a7.61,7.61,0,0,1-9.08-1.26,7.58,7.58,0,0,1-1.27-9.06,14.26,14.26,0,0,1-.37-2.85,9.58,9.58,0,0,0,.22,13.33,9.63,9.63,0,0,0,13.35.22A14.46,14.46,0,0,1,18.41,27.68Z"></path> <path d="M21.66,26.21a12.1,12.1,0,1,1,8.57-3.54h0A12.11,12.11,0,0,1,21.66,26.21ZM21.66,4A10.11,10.11,0,0,0,11.54,14.11a10,10,0,0,0,3,7.14,10.12,10.12,0,0,0,14.31,0A10.11,10.11,0,0,0,21.66,4Zm7.86,18h0Z"></path> </g> </g></svg>,
      label: 'Animate',
      key: 'animate',
      onClick: () => { setShowAnimations(true) }
    }
  ];

  const handleLock = () => {
    object.set({
      lockMovementX: !lock,
      lockMovementY: !lock,
      hasControls: !!lock
    });
    editor.canvas.requestRenderAll();
    setLock(!lock);
    editor.fireCustomModifiedEvent();
  }

  const handleOpacity = (v) => {
    object.set('opacity', v);
    setOpacity(v);
    editor.canvas.requestRenderAll();
    editor.fireCustomModifiedEvent();
  }

  const handleFlip = (key) => {
    object.set(key, !object[key]);
    editor.canvas.requestRenderAll();
    editor.fireCustomModifiedEvent();
  }

  const alignObject = (alignType) => {
    switch (alignType) {
      case 'center':
        editor.canvas.viewportCenterObject(object);
        object.setCoords();
        break;
      case 'left':
        object.set('left', 0);
        break;
      case 'centerH':
        editor.canvas.viewportCenterObjectH(object);
        object.setCoords();
        break;
      case 'right':
        object.set('left', editor.sketch.width - object.width);
        break;
      case 'top':
        object.set('top', 0);
        break;
      case 'centerV':
        editor.canvas.viewportCenterObjectV(object);
        object.setCoords();
        break;
      case 'bottom':
        object.set('top', editor.sketch.height - object.height);
        break;
      default:
        break;
    }
    editor.canvas.requestRenderAll();
    editor.fireCustomModifiedEvent();
  }

  useEffect(() => {
    if (object) {
      setLock(object.lockMovementX);
      setOpacity(object.opacity);
    }else{
      setShowAnimations(false)
    }
  }, [object]);

  if (!object || object.id === SKETCH_ID) return null;

  return (
    <div className='toolbar-item-common-setter'>
      <FList 
        dataSource={IMAGE_ANIMATIONS_CONFIG}
        renderItemChildren={(item) => (
          <>
            {item.icon}
            <span style={{ fontSize: 16, fontWeight: 'bold', margin: '0 6px 0 10px' }}>
              {item.label}
            </span>
            <RightOutlined />
          </>
        )}
      />
      <ToolbarItem tooltipProps={{ placement: 'top' }} onClick={handleLock} title={lock ? 'Unlock position' : 'Lock position'}>
        {
          lock ?
            <UnlockOutlined style={{ fontSize: 20 }} /> :
            <LockOutlined style={{ fontSize: 20 }} />
        }
      </ToolbarItem>
      <ToolbarItem tooltipProps={{ placement: 'top' }} title="Opacity">
        <OpacitySetter value={opacity} onChange={handleOpacity} />
      </ToolbarItem>
      <ToolbarItem
        tooltipProps={{ placement: 'top' }}
        title="Duplicate"
        onClick={
          async () => {
            await copyObject(editor.canvas, object);
            await pasteObject(editor.canvas);
          }
        }
      >
        <CopyOutlined style={{ fontSize: 20 }} />
      </ToolbarItem>
      <ToolbarItem
        tooltipProps={{ placement: 'top' }}
        title="Delete"
        onClick={() => { removeObject(null, editor.canvas); }}
      >
        <DeleteOutlined style={{ fontSize: 20 }} />
      </ToolbarItem>
      {
        object.type === 'f-image' ?
          <ToolbarItem
            tooltipProps={{ placement: 'top' }}
            title="Flip"
          >
            <FlipSetter onChange={handleFlip} />
          </ToolbarItem> : null
      }
      {
        ALIGH_TYPES.map(item => (
          <ToolbarItem
            tooltipProps={{ placement: 'top' }}
            title={item.label}
            key={item.key}
            onClick={() => { alignObject(item.key); }}
          >
            <item.icon style={{ fontSize: 20 }} />
          </ToolbarItem>
        ))
      }
      <MoreConfigWrapper
        open={showAnimation}
        setOpen={setShowAnimations}
        title="Animations"
        
      >
        <Animate />
      </MoreConfigWrapper>
    </div>
  )
}