import { Flex, Card } from 'antd';
import Title from '@/fabritor/components/Title';

const PRESET_FONT_LIST = [
  {
    label: <div style={{ fontSize: 30, fontFamily: 'SmileySans', fontWeight: 'bold' }}>Title 1</div>,
    key: 'title',
    config: {
      fontFamily: 'SmileySans',
      fontWeight: 'bold',
      fontSize: 120,
      text: 'Title 1',
      top: 100
    }
  },
  {
    label: <div style={{ fontSize: 24, fontFamily: 'AlibabaPuHuiTi' }}>Title 2</div>,
    key: 'sub-title',
    config: {
      fontFamily: 'AlibabaPuHuiTi',
      fontWeight: 'bold',
      fontSize: 100,
      text: 'Title 2',
      top: 400
    }
  },
  {
    label: <div style={{ fontSize: 16, fontFamily: 'SourceHanSerif' }}>Title 3</div>,
    key: 'content',
    config: {
      fontFamily: 'SourceHanSerif',
      fontSize: 80,
      text: 'Title 3'
    }
  },
  {
    label: <div style={{ fontSize: 26, fontFamily: '霞鹜文楷', color: '#ffffff' , WebkitTextStroke: '1px rgb(255, 87, 87)' }}>Styled text</div>,
    key: 'content',
    config: {
      fontFamily: '霞鹜文楷',
      fontSize: 100,
      text: 'Styled text',
      fill: '#ffffff',
      stroke: '#ff5757',
      strokeWidth: 12
    }
  }
]

export default function PresetFontPanel (props) {
  const { addTextBox } = props;

  const handleClick = (item) => {
    addTextBox?.(item.config);
  }

  return (
    < >
      <div className="gaist-panel-wrapper-title">Text</div>
      <div className="gaist-panel-wrapper-search">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15.2 16.34a7.5 7.5 0 1 1 1.38-1.45l4.2 4.2a1 1 0 1 1-1.42 1.41l-4.16-4.16zm-4.7.16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
        <input type='search' placeholder='Filter...'/>
      </div>
      <Flex wrap="wrap" gap={8} >
      {
        PRESET_FONT_LIST.map((item,i) => (
          <Card
            key={`${item.key}-${i}`}
            hoverable
            onClick={() => { handleClick(item) }}
            style={{
              padding: '0px',
              width : "max-content",
              display: "flex",
              alignItems: "center"
            }}
            bodyStyle={{padding:12}}
          >
            {item.label}
          </Card>
        ))
      }
      </Flex>
    </>
  );
}