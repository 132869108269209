import axios from "axios"

const MEDIA_API = process.env.REACT_APP_MEDIA_API  || "https://api.creativ-studio.com"

export const searchMedia = async ({query,limit,offset,type}) =>{
    if(MEDIA_API){
        const searchRequest  = await axios.get(`${MEDIA_API}/media/search?type=${type}&limit=${limit}&offset=${offset}`)
        return searchRequest.data
    }
    return []
}