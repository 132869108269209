import { GloablStateContext } from '@/context';
import React, { useContext } from 'react'
import { fabric } from 'fabric';

type Props = {}


export function AnimationEffect({type,direction,onChange}) {
  return (
    <div className='gaist-setter-common-effet-box'>
      <div className='gaist-setter-common-effet-name'>{direction}</div>
      <select className='gaist-setter-common-effet-list' onChange={(e)=>{onChange(type,direction,e.target.value)}}>
        <option key={"easeInQuad"} value={"easeInQuad"}>Ease InQuad</option>
        <option key={"easeOutQuad"} value={"easeOutQuad"}>Ease OutQuad</option>
        <option key={"easeInOutQuad"} value={"easeInOutQuad"}>Ease InOutQuad</option>
        <option key={"easeInCubic"} value={"easeInCubic"}>Ease InCubic</option>
        <option key={"easeOutCubic"} value={"easeOutCubic"}>Ease OutCubic</option>
        <option key={"easeInOutCubic"} value={"easeInOutCubic"}>Ease InOutCubic</option>
        <option key={"easeInQuart"} value={"easeInQuart"}>Ease InQuart</option>
        <option key={"easeOutQuart"} value={"easeOutQuart"}>Ease OutQuart</option>
        <option key={"easeInOutQuart"} value={"easeInOutQuart"}>Ease InOutQuart</option>
        <option key={"easeInQuint"} value={"easeInQuint"}>Ease InQuint</option>
        <option key={"easeOutQuint"} value={"easeOutQuint"}>Ease OutQuint</option>
        <option key={"easeInOutQuint"} value={"easeInOutQuint"}>Ease InOutQuint</option>
        <option key={"easeInSine"} value={"easeInSine"}>Ease InSine</option>
        <option key={"easeOutSine"} value={"easeOutSine"}>Ease OutSine</option>  
        <option key={"easeInOutSine"} value={"easeInOutSine"}>Ease InOutSine</option>
        <option key={"easeInExpo"} value={"easeInExpo"}>Ease InExpo</option>
        <option key={"easeOutExpo"} value={"easeOutExpo"}>Ease OutExpo</option>
        <option key={"easeInOutExpo"} value={"easeInOutExpo"}>Ease InOutExpo</option>
        <option key={"easeInCirc"} value={"easeInCirc"}>Ease InCirc</option>
        <option key={"easeOutCirc"} value={"easeOutCirc"}>Ease OutCirc</option>
        <option key={"easeInOutCirc"} value={"easeInOutCirc"}>Ease InOutCirc</option>
        <option key={"easeInElastic"} value={"easeInElastic"}>Ease InElastic</option>
        <option key={"easeOutElastic"} value={"easeOutElastic"}>Ease OutElastic</option>
        <option key={"easeInOutElastic"} value={"easeInOutElastic"}>Ease InOutElastic</option>
        <option key={"easeInBack"} value={"easeInBack"}>Ease InBack</option>
        <option key={"easeOutBack"} value={"easeOutBack"}>Ease OutBack</option>
        <option key={"easeInOutBack"} value={"easeInOutBack"}>Ease InOutBack</option>
        <option key={"easeInBounce"} value={"easeInBounce"}>Ease InBounce</option>
        <option key={"easeOutBounce"} value={"easeOutBounce"}>Ease OutBounce</option>
        <option key={"easeInOutBounce"} value={"easeInOutBounce"}>Ease InOutBounce</option>
      </select>
    </div>
  )
}


export default function Animate({}: Props) {

  const { object, editor } = useContext(GloablStateContext);

  const handleAnimate = (type,direction,anim) =>{
    object.addAnimation({duration: 1000,direction:direction,type:type, easing: anim, properties: {left: 400}})
    .play();
  }

  return (
    <div className='common-setter-animate'>
      <div>
        <div className='gaist-setter-common-effet-title'>Slide in</div>
        <AnimationEffect type={"ease"} direction={"left"} onChange={handleAnimate}/>
        <AnimationEffect type={"ease"} direction={"right"} onChange={handleAnimate}/>
        <AnimationEffect type={"ease"} direction={"top"} onChange={handleAnimate}/>
        <AnimationEffect type={"ease"} direction={"bottom"} onChange={handleAnimate}/>

      </div>
    </div>
  )
}