import { fabric } from 'fabric';
import { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { FunctionOutlined, RightOutlined } from '@ant-design/icons';
import ReplaceSetter from '../ImageSetter/ReplaceSetter';
import { GloablStateContext } from '@/context';
import BorderSetter from '../ImageSetter/BorderSetter';
import { getObjectBorderType, getStrokeDashArray } from '../BorderSetter'
import ClipSetter from '../ImageSetter/Clip';
import FList from '@/fabritor/components/FList';
import MoreConfigWrapper from '../Form/MoreConfigWrapper';
import ImageFx from '../ImageSetter/ImageFx';
import { PAUSE_BUTTON, PLAY_BUTTON } from '@/assets/icon';

const { Item: FormItem } = Form;

export default function ImageSetter () {
  const { object, editor } = useContext(GloablStateContext);
  const [form] = Form.useForm();
  const [openFx, setOpenFx] = useState(false);

  const IMAGE_ADVANCE_CONFIG = [
    {
      icon: <FunctionOutlined style={{ fontSize: 22 }} />,
      label: 'Filters',
      key: 'fx',
      onClick: () => { setOpenFx(true) }
    }
  ];

  const handleImageReplace = (base64) => {
    if (base64) {
      (object as fabric.Image).setSrc(base64, () => {
        editor.canvas.requestRenderAll();
      });
    }
  }

  // const handleBorder = (border) => {
  //   const { type, stroke = '#000000', strokeWidth, borderRadius } = border || {};
  //   if (type === 'none') {
  //     object.setBorder({ stroke: null, borderRadius });
  //   } else {
  //     object.setBorder({
  //       stroke,
  //       strokeWidth,
  //       borderRadius,
  //       strokeDashArray: getStrokeDashArray(border)
  //     });
  //   }

  //   editor.canvas.requestRenderAll();
  // }

  // const handleValuesChange = (values) => {
  //   if (values.img) {
  //     handleImageReplace(values.img);
  //   }
  //   if (values.flip) {
  //     object.set(values.flip, !object[values.flip]);
  //     editor.canvas.requestRenderAll();
  //   }
  //   if (values.border) {
  //     handleBorder(values.border);
  //   }
  //   editor.fireCustomModifiedEvent();
  // }

  // useEffect(() => {
  //   if (object) {
  //     const border = object.getBorder();
  //     form.setFieldsValue({
  //       border: {
  //         type: getObjectBorderType(border),
  //         ...border,
  //         stroke: border.stroke || '#000000'
  //       },
  //       opacity: object.opacity
  //     });
  //   }
  // }, [object]);

  const playPause = () =>{
    console.log("playPause",object)
    if(object?.getElement()){
      const videoEl = object?.getElement()
      console.log("object",videoEl?.paused)
      if (videoEl.paused) {
        object.play();
      } else {
        object.pause();
      }
    }
  }

  return (
    <>
    <Form
      form={form}
      // onValuesChange={handleValuesChange}
      colon={false}
      className='setter-content-item'
    >
      <FormItem name="img">
        <div className='setter-content-item-video'>
        <div className='setter-content-item-video-play-pause' onClick={(e)=>playPause()}>
          {object?.getElement()?.paused ? (
            <img src={PLAY_BUTTON} width={24} height={24}/>
          ):(<img src={PAUSE_BUTTON} width={24} height={24}/>)}
          </div>
        </div>
      </FormItem>
      {/* <FormItem name="img">
        <ReplaceSetter />
      </FormItem>
      <Row gutter={8}>
        <Col span={12}>
          <FormItem>
            <ClipSetter object={object} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name="border">
            <BorderSetter />
          </FormItem>
        </Col>
      </Row> */}
    </Form>
    {/* <FList 
      dataSource={IMAGE_ADVANCE_CONFIG}
      renderItemChildren={(item) => (
        <>
          {item.icon}
          <span style={{ fontSize: 16, fontWeight: 'bold', margin: '0 6px 0 10px' }}>
            {item.label}
          </span>
          <RightOutlined />
        </>
      )}
    />
    <MoreConfigWrapper
      open={openFx}
      setOpen={setOpenFx}
      title="Filters"
    >
      <ImageFx />
    </MoreConfigWrapper> */}
    </>
  )
}