import { fabric } from 'fabric';


export const createFVideoClass = () => {
  fabric.FVideo = fabric.util.createClass(fabric.Image, {
    type: 'f-video',
    initialize: function(element, options) {
      console.log("createFVideoClass",element)
      this.callSuper('initialize', element.image, options);
      element.image.loop = true;
      element.image.muted = true;
      element.image.playsInline = true;
      element.image.pause();
    },
    _render: function(ctx) {
      this.callSuper('_render', ctx);
      ctx.drawImage(this.getElement(), -this.width / 2, -this.height / 2, this.width, this.height);
    },
    play: function() {
      this.getElement().play();
      this._animate();
    },
    pause: function() {
      this.getElement().pause();
    },
    _animate: function() {
      if (!this.getElement().paused && this.canvas) {
        console.log("createFVideoClass updating canvas")

        this.canvas.requestRenderAll();
        fabric.util.requestAnimFrame(this._animate.bind(this));
      }
    },
    toObject: function() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        src: this.getSrc(),
      });
    },
    _createBorderRect ({ stroke, strokeWidth, borderRadius }) {
      const width = this.img.getScaledWidth();
      const height = this.img.getScaledHeight();
      const options = {
        width,
        height,
        rx: borderRadius || 0,
        ry: borderRadius || 0,
        originX: 'center',
        originY: 'center',
        fill: '#00000000',
        paintFirst: 'fill'
      };
      if (stroke) options.stroke = stroke;
      if (strokeWidth) options.strokeWidth = strokeWidth;
      return new fabric.Rect(options);
    },

    _createClipPath () {
      const width = this.img.width;
      const height = this.img.height;
      // console.log(width, height)
      return new fabric.Rect({
        originX: 'center',
        originY: 'center',
        width,
        height,
        rx: this.borderRect.rx || 0,
        ry: this.borderRect.ry || 0
      });
    },
    setBorder (b) {
      this.borderRect.set({
        stroke: b.stroke || null,
        strokeWidth: b.strokeWidth || 1,
        rx: b.borderRadius || 0,
        ry: b.borderRadius || 0,
        strokeDashArray: b.strokeDashArray || null
      });
      this.img.setCoords();
      this.img.set({
        clipPath: this._createClipPath(),
        dirty: true
      });
      this.imageBorder = {...b};
      this.addWithUpdate();
    },

    getBorder () {
      return this.imageBorder;
    },

    // http://fabricjs.com/fabric-filters
    applyFilter (filter) {
      try {
        this.img.filters = filter ? [filter] : [];
        this.img.applyFilters();
      } catch(e) {
        console.log(e);
      }
    },

    applyFilterValue (prop, value) {
      const filter = this.getFilter();
      if (filter) {
        filter[prop] = value;
        this.img.filters = [filter];
        this.img.applyFilters();
      }
    },

    getFilter () {
      return this.img.filters[0];
    }
  });
  
  fabric.FVideo.fromURL = function(url, callback, imgOptions) {
    const video = document.createElement('video');
    video.src = url;
    video.autoplay = false;
    video.loop = true;
    video.muted = true;
    video.playsInline = true;
  
    video.addEventListener('loadeddata', function() {
      if (callback) {
        callback(new fabric.FVideo({image: video}, imgOptions));
      }
    });
  
    video.addEventListener('error', function(err) {
      console.error('Failed to load video', err);
    });
  };

  fabric.FVideo.fromObject = async (object, callback) => {
    console.log("fabric.FVideo.fromObject",object)
    const {src,...imgOptions} = object
    const video = document.createElement('video');
    video.src = object.src;
    video.autoplay = false;
    video.loop = true;
    video.muted = true;
    video.playsInline = true;
  
    video.addEventListener('loadeddata', function() {
      if (callback) {
        callback(new fabric.FVideo({image: video}, imgOptions));
      }
    });
  
    video.addEventListener('error', function(err) {
      console.error('Failed to load video', err);
    });
  }
  
}