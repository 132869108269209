import { Dropdown, Button, Divider, message } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { downloadFile, base64ToBlob, downloadFilesAsZip, downloadVideo } from '@/utils';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GloablStateContext } from '@/context';
import LocalFileSelector from '@/fabritor/components/LocalFileSelector';
import { CenterV } from '@/fabritor/components/Center';
import { SETTER_WIDTH } from '@/config';
import  './index.scss'
import CustomSelect from '@/components/CustomSelect';
import Editor from '@/editor';
import JSZip from 'jszip';
import { saveAs } from 'file-saver'; // FileSaver is used to save the generated zip file

const items: MenuProps['items'] = [
  {
    key: 'jpg',
    label: 'Export as JPG'
  },
  {
    key: 'png',
    label: 'Export as PNG'
  },
  {
    key: 'svg',
    label: 'Export as SVG'
  },
  {
    type: 'divider'
  },
  {
    key: 'json',
    label: 'Save as'
  },
  // {
  //   key: 'clipboard',
  //   label: 'Copy to clipboard'
  // }
]

const imgIcon = <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.2639 15.9375L12.5958 14.2834C11.7909 13.4851 11.3884 13.086 10.9266 12.9401C10.5204 12.8118 10.0838 12.8165 9.68048 12.9536C9.22188 13.1095 8.82814 13.5172 8.04068 14.3326L4.04409 18.2801M14.2639 15.9375L14.6053 15.599C15.4112 14.7998 15.8141 14.4002 16.2765 14.2543C16.6831 14.126 17.12 14.1311 17.5236 14.2687C17.9824 14.4251 18.3761 14.8339 19.1634 15.6514L20 16.4934M14.2639 15.9375L18.275 19.9565M18.275 19.9565C17.9176 20 17.4543 20 16.8 20H7.2C6.07989 20 5.51984 20 5.09202 19.782C4.71569 19.5903 4.40973 19.2843 4.21799 18.908C4.12796 18.7313 4.07512 18.5321 4.04409 18.2801M18.275 19.9565C18.5293 19.9256 18.7301 19.8727 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V16.4934M4.04409 18.2801C4 17.9221 4 17.4575 4 16.8V7.2C4 6.0799 4 5.51984 4.21799 5.09202C4.40973 4.71569 4.71569 4.40973 5.09202 4.21799C5.51984 4 6.07989 4 7.2 4H16.8C17.9201 4 18.4802 4 18.908 4.21799C19.2843 4.40973 19.5903 4.71569 19.782 5.09202C20 5.51984 20 6.0799 20 7.2V16.4934M17 8.99989C17 10.1045 16.1046 10.9999 15 10.9999C13.8954 10.9999 13 10.1045 13 8.99989C13 7.89532 13.8954 6.99989 15 6.99989C16.1046 6.99989 17 7.89532 17 8.99989Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
const videoIcon = <svg viewBox="0 0 24 24" width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M9.1001 12V10.52C9.1001 8.60999 10.4501 7.83999 12.1001 8.78999L13.3801 9.52999L14.6601 10.27C16.3101 11.22 16.3101 12.78 14.6601 13.73L13.3801 14.47L12.1001 15.21C10.4501 16.16 9.1001 15.38 9.1001 13.48V12Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>

export default function Export () {

  const { editor, setReady, setActiveObject } = useContext(GloablStateContext);
  const [showExport,setShowExport] = useState(false)
  const [exportType,setExportType] = useState("png")
  const [exportPages,setExportPages] = useState<string | string[]>("all")
  const [downloading,setDownloading] = useState(false)
  
  const localFileSelectorRef = useRef<any>();
  const boxRef = useRef(null);
  const exportButtonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      boxRef.current && !boxRef.current.contains(event.target) 
      && exportButtonRef.current && !exportButtonRef.current.contains(event.target)
    ) {
      setShowExport(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectJsonFile = () => {
    localFileSelectorRef.current?.start?.();
  }

  const handleFileChange = (file) => {
    setReady(false);
    const reader = new FileReader();
    reader.onload = (async (evt) => {
      const json = evt.target?.result as string;
      if (json) {
        await editor.loadFromJSON(json, true);
        editor.fhistory.reset();
        setReady(true);
        setActiveObject(null);
        editor.fireCustomModifiedEvent();
      }
    });
    reader.readAsText(file);
  }

  const copyImage = async () => {
    try {
      const png = editor.export2Img({ format: 'png' });
      const blob = await base64ToBlob(png);
      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': blob
        })
      ]);
      message.success('复制成功');
    } catch(e) {
      message.error('复制失败，请选择导出到本地');
    }
  }

  const getExporter = (key:string,editor:Editor):string =>{
        // @ts-ignore
    switch (key) {
      case 'png':
        return editor.export2Img({ format: 'png' });
      case 'jpg':
        return editor.export2Img({ format: 'jpg' });
      case 'svg':
        return editor.export2Svg();
      default:
        return null;
    }

  }

  const handleClick = useCallback(async (e:any) => {

    var key = exportType
    var pages = exportPages
    const { sketch } = editor;
    // @ts-ignore
    const name = sketch.fabritor_desc;
    setDownloading(true)

    if(['png','jpg','svg']?.includes(key)){
  
      if(editor?.creation?.length > 0){

        const zip = new JSZip();

        const exporters = (await Promise.all(editor?.creation?.map(async (cr)=>{
          if(pages != 'all' && !pages?.includes(cr?.position?.toString()))
            return null
    
          const newCanvas = document.createElement('canvas')
          
          // Set the width and height of the new canvas
          if (cr?.clipPath?.width && cr?.clipPath?.height) {
            newCanvas.width = cr.clipPath.width;
            newCanvas.height = cr.clipPath.height;
          } else {
            // Set default values if dimensions are not provided
            newCanvas.width = 1080;  // Default width
            newCanvas.height = 1920; // Default height
          }
    
          const _editor = new Editor({
            canvasEl: newCanvas,
            workspaceEl: newCanvas,
            sketchEventHandler: {
              groupHandler: () => { setActiveObject(_editor.canvas.getActiveObject()) }
            }
          });
    
          await _editor.init_preview(cr)
          const dataurlstring  = getExporter(key,_editor)
          const base64Content = dataurlstring.split(',')[1];
          const fileName = `${name}-${cr.position}.${key}`;

          // Add file to zip
          zip.file(fileName,base64Content , { base64: true });
        
          return {content : dataurlstring, type : key, name : name }
    
        })))?.filter(e=>e)
        
        console.log("Exporter length",exporters?.length )
      
        if(exporters?.length > 1){
          // Generate the zip file and trigger download
          zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, 'files.zip');
          });
          // downloadFilesAsZip(exporters);
        }else{
          const singleExporter = exporters?.[0]
          downloadFile(singleExporter.content,singleExporter?.type,singleExporter?.name)
        }
      }

    }else if(key =='clipboard'){
      copyImage();
    }else if (["mp4","webm"]?.includes(key)){
      await editor.exportToMP4()
    }
    setDownloading(false)
    return
    /*
    const _editor = new Editor({
      canvasEl: canvasEl.current,
      workspaceEl: workspaceEl.current,
      sketchEventHandler: {
        groupHandler: () => { setActiveObject(_editor.canvas.getActiveObject()) }
      }
    });

    await _editor.init();
    */
  
    switch (key) {
      case 'png':
        const png = editor.export2Img({ format: 'png' });
        downloadFile(png, 'png', name);
        break;
      case 'jpg':
        const jpg = editor.export2Img({ format: 'jpg' });
        downloadFile(jpg, 'jpg', name);
        break;
      case 'svg':
        const svg = editor.export2Svg();
        downloadFile(svg, 'svg', name);
        break;
      case 'json':
        const json = editor.canvas2Json();
        downloadFile(`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(json, null, 2)
        )}`, 'json', name);
        break;
      case 'clipboard':
        copyImage();
        break;
      default:
        break;
    }
  },[exportType,exportPages,editor])

  const exports = [
    {
      key : 'jpg',
      content : <span>JPEG</span>,
      icon : imgIcon
    },
    {
      key : 'png',
      content : <span>PNG</span>,
      icon : imgIcon
    },
    {
      key : 'svg',
      content : <span>SVG</span>,
      icon : imgIcon
    },
    {
      key : 'mp4',
      content : <span>MP4</span>,
      icon : videoIcon
    }
  ]

  // console.log("Export type",exportType,exportPages)
  return (
    <CenterV
      justify="flex-end"
      gap={16}
      style={{
        width: SETTER_WIDTH,
        paddingRight: 16
      }}
    >
      <Button onClick={selectJsonFile} style={{background:"transparent",color:"var(--text-color)"}}>
        Import
      </Button>
      {/* <Dropdown 
        menu={{ items, onClick: handleClick }} 
        arrow={{ pointAtCenter: true }}
        placement="bottom"
        trigger={['click']}
        
      > */}
      
      <Button ref={exportButtonRef} type="primary" icon={<ExportOutlined />} onClick={(e)=>{setShowExport(!showExport)}}>Share</Button>
      {/* </Dropdown> */}
      {showExport ?(
        <div ref={boxRef} className='export-crea-box-wrapper'>
          <div className='export-crea-box' >
            <div className='export-crea-box-top'>

              <CustomSelect
                label='Share your design'
                initialValue={exportType}
                items={exports}
                onChange={(e)=>{setExportType(e as string)}}
              />
              
              <CustomSelect
                label='Selected pages'
                initialValues={exportPages}
                onChange={(e)=>{setExportPages(e)}}
                items={editor?.creation?.map((crea,idx)=>{
                  return  {
                    key : `${crea?.position }`,
                    value : crea?.position,
                    content : `Page ${idx+1}`,
                  }
                })}
                multi={true}
              />
              <Button className='export-crea-box-download' loading={downloading} onClick={(e)=>{handleClick(e)}}>Download</Button>
            </div>
            <div className='export-crea-box-spacer'>

            </div>
          </div>
        </div>
        ):<></>
      }
      <LocalFileSelector accept="application/json" ref={localFileSelectorRef} onChange={handleFileChange} />
    </CenterV>
  )
}



