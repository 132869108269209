import { fabric } from 'fabric';
import { uuid } from '@/utils';
import { message } from 'antd';
import { PAUSE_BUTTON, PLAY_BUTTON } from '@/assets/icon';
import { CDN_URL, MEDIA_BASE_URL } from '@/config';

export const loadImageDom = async (url) => {
  var cdn_url = url?.replace(MEDIA_BASE_URL,CDN_URL)
  return new Promise((resolve, reject) => {
    fabric.util.loadImage(cdn_url, (img) => {
      if (img) {
        return resolve(img);
      }
      message.error('加载图片失败');
      return reject();
    }, null, 
    'anonymous'
  );
  });
}

export const loadImage = async (imageSource:string) => {
  if (typeof imageSource === 'string') {
    var cdn_url = imageSource?.replace(MEDIA_BASE_URL,CDN_URL)
    return new Promise<fabric.Image>((resolve, reject) => {
      console.log("loadImage hereee")
      fabric.Image.fromURL(cdn_url, (img) => {
        if (!img) {
          message.error('加载图片失败');
          reject();
          return;
        }
        resolve(img);
      }, {
        crossOrigin: 'anonymous'
        // crossOrigin: ''
      });
    });
  }
  return Promise.resolve(new fabric.Image(imageSource));
}

export const loadVideo = async (videoSource:string) => {

  var cdn_url = videoSource?.replace(MEDIA_BASE_URL,CDN_URL)

  return new Promise<fabric.Image>((resolve, reject) => {

    const video = document.createElement('video');
    video.src = cdn_url;
    video.autoplay = false;
    video.loop =  true;   
    video.crossOrigin = 'anonymous';
    video.muted = true; // Mute if you don't want sound, or handle sound separately
    video.playsInline = true;


    video.addEventListener('loadeddata', (metadata) => {
      var theVideoEl = metadata?.target as any
      console.log("loadVideo hereee", theVideoEl?.videoWidth, theVideoEl?.videoHeight)
      video.width = theVideoEl.videoWidth || 150
      video.height = theVideoEl.videoHeight || 150 * 16 / 9
      const videoElement = new fabric.Image(video, {
        left: 0,
        top: 0,
        width: theVideoEl.videoWidth || 150,
        height: theVideoEl.videoHeight || 150 * 16 / 9,
        originX: 'left',
        originY: 'top',
        objectCaching: false, // Disable caching for videos,
        type: 'video'
      });

      resolve(videoElement);
    });

    video.addEventListener('error', (err) => {
      message.error('加载视频失败');
      reject(err);
    });
  });
}

export const createClipRect = (object, options = {}) => {
  const width = object.getScaledWidth();
  const height = object.getScaledHeight();
  return new fabric.Rect({
    left: -width / 2,
    top: -height / 2,
    width,
    height,
    ...options
  });
}

export const createImage = async (options) => {
  const { imageSource, canvas, ...rest } = options || {};
  let img!: fabric.Image;
  try {
    img = await loadImage(imageSource);
  } catch (e) { console.log(e); }

  if (!img) return;

  img.set({
    ...rest,
    paintFirst: 'fill',
    id: uuid()
  });

  canvas.viewportCenterObject(img);
  canvas.add(img);
  canvas.setActiveObject(img);
  canvas.requestRenderAll();

  return img;
}

export const createFVideo = async (options) => {
  const { videoSource, canvas } = options || {};

  let videoElement;
  try {
    const fabricVideo = await loadVideo(videoSource);
    videoElement = fabricVideo.getElement(); // Extract the video element from fabric.Image
    // videoElement.addEventListener('loadeddata', () => {
    //   videoElement.play(); // Start playing the video
    //   console.log('Video loaded');
    // });
  } catch (e) {
    console.error('Failed to create video', e);
    return;
  }

  // const videoObj = new fabric.Image(videoElement, {
  //   left: 0,
  //   top: 0,
  //   originX: 'left',
  //   originY: 'top',
  //   width: videoElement.width,
  //   height: videoElement.height,
  //   objectCaching: false,
  //   type: 'video',
  // });

  const expectedWidth = canvas.getWidth() * 0.8;
  const expectedHeight = (expectedWidth * videoElement.height) / videoElement.width;
  const scaleX = expectedWidth / videoElement.width;
  const scaleY = expectedHeight / videoElement.height;
  const elementId = uuid();

  const fVideoObj = new fabric.FVideo({
    image: videoElement,
    scaleX: scaleX,
    scaleY: scaleY,
    mediaType: 'video',
    id: elementId,
  });

  canvas.viewportCenterObject(fVideoObj);
  canvas.add(fVideoObj);
  canvas.setActiveObject(fVideoObj);
  canvas.requestRenderAll();


  // const updateCanvas = () => {
  //   canvas.requestRenderAll();
  //   requestAnimationFrame(updateCanvas);
  // };
  
  // updateCanvas();

};


export const createFImage = async (options) => {
  const { imageSource, canvas } = options || {};

  let img!: fabric.Image;
  try {
    img = await loadImage(imageSource);
  } catch (e) { console.log("Failes to createFIMAGE", e); }

  if (!img) return;
  var expectedWidth = canvas.getWidth() * 0.8;
  var expectedHeight = expectedWidth * img.height / img.width
  var scaleX = expectedWidth / img.width
  var scaleY = expectedHeight / img.height

  const fimg = new fabric.FImage({
    image: img,
    scaleX: scaleX,
    scaleY: scaleY,
    mediaType: "image",
    id: uuid()
  });

  canvas.viewportCenterObject(fimg);
  canvas.add(fimg);
  canvas.setActiveObject(fimg);
  canvas.requestRenderAll();
}

export const createFImageHeigHtBased = async (options) => {
  const { imageSource, canvas,height } = options || {};

  let img!: fabric.Image;
  try {
    img = await loadImage(imageSource);
  } catch (e) { console.log("Failes to createFIMAGE", e); }

  if (!img) return;

  var expectedHeight =canvas.getHeight() * (height||1)
  var expectedWidth = img.width * expectedHeight / img.height
 var left = -(expectedWidth /2)+ (canvas.getWidth()/2)
  var scaleX = expectedWidth / img.width
  var scaleY = expectedHeight / img.height

  const fimg = new fabric.FImage({
    image: img,
    scaleX: scaleX,
    scaleY: scaleY,
    left : left,
    top : 0,
    mediaType: "image",
    id: uuid()
  });

  canvas.viewportCenterObject(fimg);
  canvas.add(fimg);
  canvas.setActiveObject(fimg);
  canvas.requestRenderAll();
}

export const applyTemplate = async (options) => {
  const { template, editor,setReady,setActiveObject } = options || {};
  if(editor){
    setReady(false);
    await editor.loadFromJSON(template, true);
    editor.fhistory.reset();
    setReady(true);
    setActiveObject(null);
    editor.fireCustomModifiedEvent();
  }
}