import { LOGO_ICON, LOGO_NAME } from '@/assets/icon';
import { CenterV } from '@/fabritor/components/Center';
import { PANEL_WIDTH } from '@/config';
import { Tag } from 'antd';

export default function Logo () {
  return (
    <CenterV gap={5} style={{ width: PANEL_WIDTH, paddingLeft: 16 }}>
      <img src={LOGO_ICON} style={{ width: 40, height:24, color:"#fff" }} />
      <img src={LOGO_NAME} style={{ width: 100 }} />
      <Tag color='#1677ff' style={{
        padding: '0 4px',
        fontStyle:"italic",
        // fontSize: ".6rem",
        marginBottom: "1rem",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>beta</Tag>

      {/* <span style={{ fontWeight: 'bold', fontSize: 14 }}>
        A creative editor based on fabricjs.
      </span> */}
    </CenterV>
  )
}