// LOCAL
export const LOAD_FROM_LOCAL_WHEN_INIT = true;
export const AUTO_SAVE_WHEN_CHANGE = true;

// UI enhance
export const HOVER_OBJECT_CORNER = true;
export const HOVER_OBJECT_CONTROL = true;

// Image ClipPath bug?
// https://github.com/fabricjs/fabric.js/issues/5639
// but will cause low performance
export const IMAGE_CLIPPATH_QUALITY = false;

export const MAX_HISTORY_LENGTH = 100;

export const PANEL_WIDTH = 430;
export const SETTER_WIDTH = 280;

export const CAPTURE_SUBTARGET_WHEN_DBLCLICK = false;

export const LOAD_JSON_IGNORE_LOAD_FONT = false;

export const GAIST_AI_MODEL = "http://localhost:1234/v1/completions"

export const CDN_URL = "https://ik.imagekit.io/l93htbc1n"

export const MEDIA_BASE_URL = "https://promethee-assets.s3.uk.io.cloud.ovh.net"

export const TEXT_GEN_MODEL_API = "http://localhost:1234"
export const IMAGE_PROCESSING_ENDPOINT = "http://localhost:5000"