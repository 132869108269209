import React, { useCallback, useContext, useState } from 'react'
import './index.scss'
import { GloablStateContext } from '@/context';
import ScenesIcons, { DurationIcons } from '@/components/Icons/scenes';
import BaseTimeline from './Base';

type Props = {}

export default function Timeline({ }: Props) {

  const [opened, close] = useState(false)
  const [timeLineType,setTimelineType] = useState("base")

  const { editor} = useContext(GloablStateContext);

  const renderTimeline = useCallback(()=>{
    switch(timeLineType){
      // case "base":
      //   return <BaseTimeline/>
      // case "video":
      //   return <VideoTimeline/>
      default:
        return <BaseTimeline/>
    }
  },[timeLineType])

  return (
    <div className='editor-timeline' >
      <button className='editor-timeline-closer' onClick={(e) => { close(!opened) }}>
        <div className='editor-timeline-closer-content'>
          <svg className='editor-timeline-closer-bg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 16" width="96" height="16" fill="none">
            <path stroke='rgb(232, 232, 232)' d="M0 16v-3c20 0 12-12 32-12h32c20 0 12 12 32 12"></path>            {/* <path fill='#000'  d="M0 13.5c20 0 12-12 32-12h32c20 0 12 12 32 12"></path> */}
          </svg>
          {opened ? (
            <svg className='editor-timeline-closer-ic' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path fill="none" stroke="#ccc" strokeLinecap="round" strokeWidth="4" d="M3.17 5 5.3 7.12a1 1 0 0 0 1.42 0L8.83 5"></path>
            </svg>

          ) : (
            <svg className='editor-timeline-closer-ic' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path fill="none" stroke="#ccc" strokeLinecap="round" strokeWidth="4" d="M8.83 7 6.7 4.88a1 1 0 0 0-1.42 0L3.17 7"></path>
            </svg>

          )}
        </div>
      </button>
      {opened && (
        <div className='editor-timeline-box'>
          {renderTimeline()}
        </div>
      )}
      <div className='editor-timeline-top'>
        <div className={'editor-timeline-top-scene-indicator'} onClick={(e)=>{setTimelineType("base")}}>
          <ScenesIcons style={{}}/>
          <span>{`${editor?.currentIndex}/${editor?.creation?.length}`}</span>
        </div>
        {opened ? (
          <div className={'editor-timeline-top-scene-indicator'} onClick={(e)=>{setTimelineType("video")}}>
            <DurationIcons style={{}}/>
            <span>{`Timeline`}</span>
          </div>
        ):<></>}
      </div>
      
    </div>
  )
}