import React, { useEffect, useMemo, useRef, useState } from 'react'
import './index.scss'

type ItemsProps = {
    key: string,
    label?: string,
    value?: string | number,
    icon?: any,
    right?: any,
    content?: any,
}

type Props = {
    label?: string,
    initialValue?: string ,
    initialValues?: string[] | string,
    items: ItemsProps[],
    multi?: boolean,
    onChange?: (key: string | string[]) => void
}

const arrowDown = <svg width={14} viewBox="0 -4.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="currentColor"> <g id="icons" transform="translate(56.000000, 160.000000)"> <path d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583" > </path> </g> </g> </g> </g></svg>

export default function CustomSelect({ label, initialValue,initialValues, items, onChange, multi = false }: Props) {

    const [showDropDown, setShowDropDown] = useState(false)
    const [currentSelection, setCurrentSelection] = useState(initialValue)
    const [currentCheckedList, setCurrentCheckedList] = useState<string[]|string>(initialValues)
    const boxRef = useRef(null);
    const inputRef = useRef(null);

    const handleClickOutside = (event) => {
      if (
        boxRef.current && !boxRef.current.contains(event.target)
        && inputRef.current && !inputRef.current.contains(event.target)
    ) {
        setShowDropDown(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const currentItem = useMemo(() => {
        if (!items || items?.length == 0) {
            return null
        }
        return items.find((item) => { return item.key == currentSelection })
    }, [items, currentSelection])

    const currentSelections = useMemo(() => {
        if (!items || items?.length == 0) {
            return []
        }
        return items.map((item) => { 

            if( currentCheckedList == 'all'){
                return item.key
            }
            if(currentCheckedList?.includes(item.key)){
                return item.key 
            }
            return null
        })
    }, [currentCheckedList, items])?.filter(e=>e)


    const handleUpdate = (value: string) => {
        !multi && setCurrentSelection(value)
        !multi && setShowDropDown(false)
        
        if(multi){

            var arrayList = typeof currentCheckedList != "string" ? currentCheckedList : []

            if (typeof currentCheckedList == "string"){
                if(currentSelections?.length > 0){
                    arrayList = currentSelections
                }
            }
            var newSelected = []
            if(arrayList?.includes(value)){
                newSelected = arrayList?.filter((ck)=>{return ck != value})?.sort()
            }else{
                newSelected =[...arrayList,value]?.sort()
            }
            setCurrentCheckedList(newSelected)
            onChange && onChange(newSelected)

        }else{
            onChange && onChange(value)

        }
    }

    return (
        <div className='custom-select'>
            {label ? <div className='custom-select-tile'>{label}</div> : <></>}
            <div className='custom-select-dropdown' ref={inputRef} onClick={(e) => { setShowDropDown(!showDropDown) }}>
                {!multi ? (
                    <CustomSlectItem item={{ ...currentItem, right: arrowDown }} />
                ):(
                    <CustomSlectItem 
                        item={{ 
                            key : 'all',
                            content : currentCheckedList?.toString(),
                            
                        }} 
                        selected={currentCheckedList == 'all' || currentCheckedList?.length == items?.length}
                        multi
                    />
                )}
            </div>
            <div ref={boxRef} className={`custom-select-dropdown-list ${showDropDown ? 'custom-select-dropdown-visible' : ''}`}>
                {items?.map((item, idx) => (
                    <CustomSlectItem key={idx} 
                        item={item} 
                        onClick={(e) => { handleUpdate(item.key) }} 
                        selected={currentSelections?.includes(item?.key)} 
                        multi={multi}
                    />
                ))}
            </div>
        </div>
    )

}

export function CustomSlectItem({
    item,
    onClick,
    selected = false,
    multi = false
}: {
    item: ItemsProps,
    onClick?: (e: any) => void,
    selected?: boolean,
    multi?:boolean
}) {



    return (
        <div className='custom-select-dropdown-item' onClick={(e) => { onClick && onClick(e) }}>
            <div className='custom-select-dropdown-item-left'>
                {item?.icon ? <div className='custom-select-dropdown-item-icon'>{item?.icon}</div> : <></>}
                <div className='custom-select-dropdown-item-child'>{item?.content || item?.value}</div>
            </div>
            <div className='custom-select-dropdown-item-right'>
                {multi ? <input readOnly className='custom-select-dropdown-item-checkbox' type='checkbox' checked={selected} /> : <></>}
                {item?.right ? <div className='custom-select-dropdown-item-icon'>{item?.right}</div> : <></>}
            </div>
        </div>
    )
}

