import { createFImage } from '@/editor/objects/image';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ImageSelector from '@/fabritor/components/ImageSelector';
import { GloablStateContext } from '@/context';
import {Input, Segmented, Tabs } from 'antd';
import './index.scss'
import { searchMedia } from '@/services/media';
import {debounce} from 'lodash';
const urls = [
  {
    url : `https://picsum.photos/seed/1/200/${Math.floor(200*16/9)}`,
    width: 200,
    height : 200*16/9,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/1/200/${Math.floor(200*16/9)}`,
    width: 200,
    height : 200*16/9,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/1/200/${Math.floor(200*16/9)}`,
    width: 200,
    height : 200*16/9,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/2/200/${200}`,
    width: 200,
    height : 200,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/${Math.floor(200*9/16)}/${200}`,
    width: 200*16/9,
    height : 200,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/${Math.floor(200*9/16)}/${200}`,
    width: 200*16/9,
    height : 200,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/${Math.floor(200*9/16)}/${200}`,
    width: 200*16/9,
    height : 200,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/1/200/${Math.floor(200*16/9)}`,
    width: 200,
    height : 200*16/9,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/1/200/${Math.floor(200*16/9)}`,
    width: 200,
    height : 200*16/9,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/1/200/${Math.floor(200*16/9)}`,
    width: 200,
    height : 200*16/9,
    type : 'image'
  },
  {
    url : `https://picsum.photos/seed/2/200/${200}`,
    width: 200,
    height : 200,
    type : 'image'
  }
]

const videos = [
  {
    url : `https://picsum.photos/seed/1/200/${Math.floor(200*16/9)}`,
    width: 200,
    height : 200*16/9,
    type : 'image'
  },
]

const resizeImage = (media, maxHeight) => {
  const aspectRatio = media.width / media.height;
  const height = maxHeight;
  const width = Math.floor(aspectRatio * height);
  return { ...media, width, height };
};

const organizeImagesIntoLines = (images, parentWidth, minHeight, maxHeight) => {
  const lines = [];
  let currentLine = [];
  let currentLineWidth = 0;
  const maxImagesPerLine = 4;

  images.forEach(image => {
    const aspectRatio = image.width / image.height;
    const height = image.height;
    const width = image.width;

    if ((currentLine?.length > 1 && ((currentLineWidth ) / parentWidth) <= 0.7) ||( currentLine?.length ==1 &&((currentLineWidth ) / parentWidth) <= 0.9)) {
      currentLine.push({ ...image, width, height });
      currentLineWidth += width;
    } else {
      lines.push(currentLine);
      currentLine = [{ ...image, width, height }];
      currentLineWidth = width;
    }
  });

  if (currentLine.length > 0) {
    lines.push(currentLine);
  }

  // Adjust line heights
  return lines.map(line => {
    if(line?.length > 1){

      const totalWidth = line.reduce((sum, img) => sum + img.width, 0);
  
      var expectedHeight = minHeight * (parentWidth - (Math.max(line?.length - 1 )*10)) / (totalWidth )
  
  
      return line.map(image => {
        const aspectRatio = image.width / image.height;
        const width =  aspectRatio * expectedHeight;
        return { ...image, width : width, height: expectedHeight };
      });
    }
    return line
  });
};

const ImageGallery = ({ medias,parentWidth }) => {
  const [lines, setLines] = useState([]);
  const minHeight = 150;
  const maxHeight = 150;
  // console.log("medias",medias)
  const handleResize = () => {
    if (medias?.length ) {
      const resizedImages = medias.map(image => resizeImage(image, minHeight));
      const newLines = organizeImagesIntoLines(resizedImages, parentWidth, minHeight, 300);
      setLines(newLines);
    }
  };

  useEffect(()=>{
    handleResize()
  },[medias])

 

  const handleDragStart = (event, url, type) => {
    const data = JSON.stringify({ url, type });
    event.dataTransfer.setData('application/json', data);
  };


  // console.log(lines)
  return (
    <div className='media-sider-grid'>
      {lines?.map((line,i)=>(
        <div key={i} className='media-sider-grid-line'>
          {line.map((image, idx) => (
            <div  
              key={idx}
              className='media-sider-grid-item' 
              style={{ 
                height : `${image?.height}px`,
                width : `${image?.width}px`,
                aspectRatio : `${Math.floor(image.width)}/${Math.floor(image.height)}`
              // gridColumn: `span ${getPositionning(image.width,image.height).col}`,
              // gridRow :`span ${getPositionning(image.width,image.height).row}`
             }}
            >
      
              <img
                key={idx}
                src={image.preview}
                width={image.width}
                height={image.height}
                 draggable
                 onDragStart={(event) => handleDragStart(event, image.src,image.type)}
                alt=""
              />
             </div> 
          ))}

        </div>
      ))}
  </div>
  );
};

const mimetypes = [
  {
    label: "Image",
    title: "Image",
    value : "image"
  },
  {
    label: "Video",
    title: "Video",
    value : "video"
  },
  {
    label: "Audio",
    title: "Audio",
    value : "audio"
  }
]
const mediaSources = [
  {
    label: "Explore",
    title: "Explore",
    value : "public"
  },
  {
    label: "My medias",
    title: "My medias",
    value : "myassets"
  },
]
type MediaTypes = 'audio' | 'image' | 'video' | string;

export default function ImagePanel () {

  const { editor } = useContext(GloablStateContext);
  const galleryRef = useRef<HTMLDivElement>(null)
  const [mimeType, setMimeType] = useState<MediaTypes>('image');
  const [search, setSearch] = useState<MediaTypes>();
  const [mediaSource, setMediaSource] = useState<MediaTypes>('public');
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [currentMedias,setCurrentMedias] = useState<any[]>()
  const [loading, setLoading] = useState<boolean>(false);
  // console.log("galleryRef.current.clientWidth",parentWidth)

  const addImage = async (url) => {
    await createFImage({
      imageSource: url,
      canvas: editor.canvas
    });
  }
 

  useEffect(() => {
    const updateParentWidth = () => {
      if (galleryRef.current) {
        setParentWidth(galleryRef.current.clientWidth);
      }
    };

    updateParentWidth(); // Initial call to set width
    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  const runMediaSearch =  useCallback( async ()=>{
    try {
      setLoading(true);
      const data = await searchMedia({
        type : mimeType,
        limit : 8,
        offset : 0,
        query : search
      })
      if(data){
        setCurrentMedias(data);
      }else {
        setCurrentMedias([]);
      }
      // const response = await fetch(`https://api.example.com/medias?type=${mimeType}`);
      // const data = await response.json();
      // setCurrentMedias(data.urls);
      // if (mimeType === 'image') {
      //   setCurrentMedias(urls);
      // } else if (mimeType === 'video') {
      //   setCurrentMedias(videos);
      // } else {
      //   setCurrentMedias([]);
      // }
   
    } catch (error) {
      console.error('Error fetching media URLs:', error);
      setCurrentMedias([]);
    } finally {
      setLoading(false);
    }
  },[mimeType,search])
  
  const fetchMediaUrls = async () => {

    debounce(async () => {
      runMediaSearch()
    }, 300)
  };

  useEffect(()=>{
    runMediaSearch();
  },[mimeType,search])

  useEffect(()=>{
    fetchMediaUrls()
  },[])

  // console.log("mimeType",mimeType,currentMedias)


  return (
    <div  className="gaist-panel-wrapper">
      <div  className="gaist-panel-wrapper-title">Medias</div>
      <div>

        <div className="gaist-panel-wrapper-search">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15.2 16.34a7.5 7.5 0 1 1 1.38-1.45l4.2 4.2a1 1 0 1 1-1.42 1.41l-4.16-4.16zm-4.7.16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
          <input value={search} onChange={(e)=>{setSearch(e?.target?.value)}} type='search' placeholder='Filter...'/>
        </div>
      </div>
      <Segmented
        defaultValue="Image"
        value={mediaSource}
        block
        style={{ margin: "1rem 0",width:"100%" }}
        onChange={(value) => {setMediaSource(value?.toLocaleString()?.toLocaleLowerCase())}}
        options={mediaSources}
        className='gaist-panel-wrapper-source'
      />
      {(mediaSource == "myassets")?(
        <ImageSelector onChange={addImage} />
      ):<></>}
      <Segmented
        defaultValue="public"
        value={mimeType}
        block
        style={{ margin: "1rem 0",width:"100%" }}
        onChange={(value) => {setMimeType(value?.toLocaleString()?.toLocaleLowerCase())}}
        options={mimetypes}
        className='gaist-panel-wrapper-mimetype'
      />
      <div ref={galleryRef} className='gaist-panel-wrapper-grid-box'>
        {currentMedias?.length > 0 ? (
          <ImageGallery key={`gallery-${parentWidth}-${mimeType}`} medias={currentMedias} parentWidth={parentWidth} />
        ):(<></>)}
      </div>
    </div>
  )
}