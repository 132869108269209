import { Form } from 'antd';
import SolidColorSetter from '../ColorSetter/Solid';
import ColorSetter from '../ColorSetter';
import SliderInputNumber from '@/fabritor/components/SliderInputNumber';
import { useEffect } from 'react';

const { Item: FormItem } = Form;

export default function PathSetterForm (props) {
  const { value, onChange, showPenTip, showFillConfig } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  return (
    <Form
      form={form}
      onValuesChange={onChange}
      style={{ marginBottom: 0, marginTop: 16,color: "var(--text-color)" }}
      colon={false}
      className='setter-content-item'
    >
      {showPenTip ? <FormItem label={<span style={{ fontSize: 15, fontWeight: 'bold',color: "var(--text-color)" }}>Brushes</span>} /> : null }
      <FormItem
        label={<span style={{color: "var(--text-color)" }}>{showFillConfig ? 'STROKE' : 'Color'}</span>} 
        name="color"
        className='color-primary'
      >
        <SolidColorSetter />
      </FormItem>
      <FormItem
        label={<span style={{color: "var(--text-color)" }}>Line width</span>} 
        name="width"
        className='color-primary'
      >
        <SliderInputNumber min={1} max={100} />
      </FormItem>
      {
        showFillConfig ?
        <FormItem
          label={<span style={{color: "var(--text-color)" }}>PADDING</span>} 
          name="fill"
          className='color-primary'
        >
          <ColorSetter />
        </FormItem> : null
      }
      <FormItem label={<span style={{ fontSize: 15, fontWeight: 'bold',color: "var(--text-color)" }}>Shadow</span>} />
      <FormItem
        label={<span style={{color: "var(--text-color)" }}>Color</span>} 
        name={['shadow', 'color']}
        className='color-primary'
      >
        <ColorSetter />
      </FormItem>
      <FormItem
        label={<span style={{color: "var(--text-color)" }}>Height</span>} 
        name={['shadow', 'width']}
        className='color-primary'
      >
        <SliderInputNumber min={0} max={50} />
      </FormItem>
      <FormItem
        label={<span style={{color: "var(--text-color)" }}>Distance</span>} 
        name={['shadow', 'offset']}
        className='color-primary'
      >
        <SliderInputNumber min={0} max={20} />
      </FormItem>
    </Form>
  )
}