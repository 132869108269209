import { useContext, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { Form, Select } from 'antd';
import { FONT_PRESET_FAMILY_LIST } from '@/utils/constants';
import { GloablStateContext } from '@/context';
import FontStyleSetter from './FontStyleSetter';
import AlignSetter from './AlignSetter';
import ColorSetter from '../ColorSetter';
import { loadFont, transformColors2Fill, transformFill2Colors } from '@/utils';
import { FunctionOutlined, RightOutlined } from '@ant-design/icons';
import SliderInputNumber from '@/fabritor/components/SliderInputNumber';
import Title from '@/fabritor/components/Title';
import FList from '@/fabritor/components/FList';
import MoreConfigWrapper from '../Form/MoreConfigWrapper';
import TextFx from './TextFx';

const { Item: FormItem } = Form;

export default function TextSetter () {
  const { object, editor }= useContext(GloablStateContext);
  const [form] = Form.useForm();
  const [openFx, setOpenFx] = useState(false);

  const TEXT_ADVANCE_CONFIG = [
    {
      icon: <FunctionOutlined style={{ fontSize: 22 }} />,
      label: 'Effects',
      key: 'fx',
      onClick: () => { setOpenFx(true) }
    }
  ]

  const handleFontStyles = (styles) => {
    object.set({
      fontWeight: styles?.bold ? 'bold' : 'normal',
      fontStyle: styles?.italic ? 'italic' : 'normal',
      underline: !!styles.underline,
      linethrough: !!styles.linethrough
    });
  }

  const handleTextStroke = (border) => {
    object.set({
      stroke: border.stroke,
      strokeWidth: border.strokeWidth
    });
  }

  const handleFill = (_fill) => {
    let fill = transformColors2Fill(_fill);
    // text gradient nor support percentage https://github.com/fabricjs/fabric.js/issues/8168  
    if (typeof fill !== 'string') {
      fill.gradientUnits = 'pixels';
      const { coords } = fill;
      fill.coords = {
        x1: coords.x1 === 1 ? object.width : 0,
        y1: coords.y1 === 1 ? object.height : 0,
        x2: coords.x2 === 1 ? object.width : 0,
        y2: coords.y2 === 1 ? object.height : 0,
        r1: 0,
        r2: object.width > object.height ? object.width / 2  : object.height
      }
    }
    if (typeof fill !== 'string') {
      fill = new fabric.Gradient(fill);
    }
    object.set({ fill });
  }

  const handleValuesChange = async (values) => {
    const keys = Object.keys(values);
    if (!keys?.length) return;

    for (let key of keys) {
      if (key === 'fontStyles') {
        handleFontStyles(values[key]);
      } else if (key === 'fontFamily') {
        try {
          await loadFont(values[key]);
        } finally {
          object.set(key, values[key]);
        }
      } else if (key === 'border') {
        handleTextStroke(values[key]);
      } else if (key === 'fill') {
        handleFill(values[key]);
      } else {
        const selectedText = object.getSelectedText();
        if (selectedText && key === 'fill') {
          object.setSelectionStyles({ fill: values[key] });
        } else {
          object.set('styles', {});
          object.set(key, values[key]);
        }
      }
    }
   
    editor.canvas.requestRenderAll();
    editor.fireCustomModifiedEvent();
  }

  const handleTextAdvanceConfigClick = (itemConfig) => {

  }

  useEffect(() => {
    form.setFieldsValue({
      fontFamily: object.fontFamily,
      fontSize: object.fontSize,
      fill: transformFill2Colors(object.fill),
      textAlign: object.textAlign,
      lineHeight: object.lineHeight,
      charSpacing: object.charSpacing,
      fontStyles: {
        bold: object.fontWeight === 'bold',
        italic: object.fontStyle === 'italic',
        underline: object.underline,
        linethrough: object.linethrough
      },
      border: {
        stroke: object.stroke,
        strokeWidth: object.strokeWidth
      }
    });
  }, [object]);

  return (
    <>
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        colon={false}
        className='setter-content-item'

      >
        <FormItem
          name="fontFamily"
          // label="Font family"
          style={{minWidth : "150px"}}
        >
          <Select
            style={{minWidth : "150px"}}
            options={FONT_PRESET_FAMILY_LIST}
          />
        </FormItem>
        <FormItem
          name="fontSize"
          // label="FS"
        >
          <SliderInputNumber max={400} />
        </FormItem>
        <FormItem
          name="fill"
          // label="Color"
        >
          <ColorSetter type="fontColor" defaultColor="#000000" />
        </FormItem>
        <FormItem
          name="textAlign"
          // label="Text ALign"
        >
          <AlignSetter />
        </FormItem>
        <FormItem
          name="fontStyles"
          // label="Design"
        >
          <FontStyleSetter />
        </FormItem>
        <FormItem
          name="charSpacing"
          // label="Char spacing"
        >
          <SliderInputNumber
            prefix={<svg width={16} height={16} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M4.55293 0.999969C4.75295 0.999969 4.93372 1.11917 5.0125 1.30301L8.01106 8.29982C8.11984 8.55363 8.00226 8.84757 7.74844 8.95635C7.49463 9.06512 7.20069 8.94754 7.09191 8.69373L6.11613 6.41685H2.98973L2.01395 8.69373C1.90517 8.94754 1.61123 9.06512 1.35742 8.95635C1.1036 8.84757 0.986023 8.55363 1.0948 8.29982L4.09336 1.30301C4.17214 1.11917 4.35291 0.999969 4.55293 0.999969ZM4.55293 2.76929L5.75186 5.56685H3.354L4.55293 2.76929ZM11.0562 9.00214C11.2617 9.00214 11.4463 8.87633 11.5215 8.68502L14.2733 1.68299C14.3743 1.42598 14.2478 1.13575 13.9908 1.03475C13.7338 0.933747 13.4436 1.06021 13.3426 1.31722L11.0562 7.13514L8.76973 1.31722C8.66873 1.06021 8.3785 0.933747 8.1215 1.03475C7.86449 1.13575 7.73802 1.42598 7.83902 1.68299L10.5908 8.68502C10.666 8.87633 10.8506 9.00214 11.0562 9.00214ZM14.9537 12.4999C14.9537 12.606 14.9115 12.7077 14.8365 12.7828L12.8365 14.7828C12.6803 14.939 12.4271 14.939 12.2708 14.7828C12.1146 14.6265 12.1146 14.3733 12.2708 14.2171L13.588 12.8999H1.51937L2.83653 14.2171C2.99274 14.3733 2.99274 14.6265 2.83653 14.7828C2.68032 14.939 2.42705 14.939 2.27084 14.7828L0.270843 12.7828C0.195828 12.7077 0.153687 12.606 0.153687 12.4999C0.153687 12.3938 0.195828 12.2921 0.270843 12.2171L2.27084 10.2171C2.42705 10.0609 2.68032 10.0609 2.83653 10.2171C2.99274 10.3733 2.99274 10.6265 2.83653 10.7828L1.51937 12.0999L13.588 12.0999L12.2708 10.7828C12.1146 10.6265 12.1146 10.3733 12.2708 10.2171C12.4271 10.0609 12.6803 10.0609 12.8365 10.2171L14.8365 12.2171C14.9115 12.2921 14.9537 12.3938 14.9537 12.4999Z" fill="#000000"></path> </g></svg>}
            min={-200}
            max={800}
          />
        </FormItem>
        <FormItem
          name="lineHeight"
          // label="Line height"
        >
          <SliderInputNumber
            prefix={<svg fill="#000000" width={16} height={16} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" ><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M648 160H104c-4.4 0-8 3.6-8 8v128c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-64h168v560h-92c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-92V232h168v64c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8zm272.8 546H856V318h64.8c6 0 9.4-7 5.7-11.7L825.7 178.7a7.14 7.14 0 0 0-11.3 0L713.6 306.3a7.23 7.23 0 0 0 5.7 11.7H784v388h-64.8c-6 0-9.4 7-5.7 11.7l100.8 127.5c2.9 3.7 8.5 3.7 11.3 0l100.8-127.5a7.2 7.2 0 0 0-5.6-11.7z"></path> </g></svg>}
            min={0.5}
            max={2.5}
            step={0.01}
          />
        </FormItem>
      </Form>
      <FList 
        dataSource={TEXT_ADVANCE_CONFIG}
        renderItemChildren={(item) => (
          <div className='sgv-white'>
            {item.icon}
            <span style={{ fontSize: 16, fontWeight: 'bold', margin: '0 6px 0 10px' }}>
              {item.label}
            </span>
            <RightOutlined />
          </div>
        )}
      />
      <MoreConfigWrapper
        open={openFx}
        setOpen={setOpenFx}
        title="Text effect"
      >
        <TextFx />
      </MoreConfigWrapper>
    </>
  )
}