import { asyncForEach, uuid } from '@/utils';
import { fabric } from 'fabric';
const clone = fabric.util.object.clone;

const additionalProps =
('fontFamily fontWeight fontSize text underline overline linethrough' +
' textAlign fontStyle lineHeight textBackgroundColor charSpacing styles' +
' direction path pathStartOffset pathSide pathAlign minWidth splitByGrapheme').split(' ');


const cleanupProperty = (s:string|number) =>{
  if(typeof s == "number"){
    return s
  }
  if(s){
    const matches = s.match(/\d+/g)
    if(matches){
      const result = parseInt(matches.join(''),10); 
      return result
    }
  }
  return 400
}
const newFab = fabric as any
export const createFTextClass = () => {
  // @ts-ignore custom f-text
  newFab.FText = fabric.util.createClass(fabric.Textbox, {
    type: 'f-text',

    padding: 0,

    paintFirst: 'stroke',

    _animationList : [],
    // initialize (options) {
    //   console.log("initialize")
    // },
    // initDimensions: function() {
    //   if (this.__skipDimension) {
    //     return;
    //   }
    //   this.isEditing && this.initDelayedCursor();
    //   this.clearContextTop();
    //   this._clearCache();
    //   // clear dynamicMinWidth as it will be different after we re-wrap line
    //   this.dynamicMinWidth = 0;
    //   // wrap lines
    //   this._styleMap = this._generateStyleMap(this._splitText());
    //   // if after wrapping, the width is smaller than dynamicMinWidth, change the width and re-wrap
    //   if (this.dynamicMinWidth > this.width) {
    //     this._set('width', this.dynamicMinWidth);
    //   }
    //   if (this.textAlign.indexOf('justify') !== -1) {
    //     // once text is measured we need to make space fatter to make justified text.
    //     this.enlargeSpaces();
    //   }
    //   // clear cache and re-calculate height
    //   const height = this.calcTextHeight();
    //   if (!this.path) {
    //     this.height = height;
    //   } else {
    //     this.height = this.path.height > height ? this.path.height : height;
    //   }
    //   this.saveState({ propertySet: '_dimensionAffectingProps' });
    // },

    toObject: function(propertiesToInclude) {
      const allProperties = additionalProps.concat(propertiesToInclude);
      const obj = this.callSuper('toObject', allProperties);
      obj.styles = newFab.util.stylesToArray(this.styles, this.text);
      obj.animations = this._animationList?.map((an)=>{
        return {
          ...an,
          properties:{
            left : cleanupProperty(an?.properties?.left)
          }
        }
      })
      if (obj.path) {
        obj.path = this.path.toObject();
      }
      return obj;
    },
    addAnimation(animation) {
      this._animationList = []
      this._animationList.push(
        Object.assign({
          id: uuid(), 
          duration: 0, 
          start: 0,
          end : 100,
          easing: "linear", 
          properties: null
        }, animation)
      );
      
      return this;
    },
    
    getPropertiesForAnimation() {
      const properties = {};
  
      this.animationProperties.forEach(prop => {
        properties[prop] = this[prop];
      })
  
      return properties;
    },
    
    getFirstAnimation() {
      return this._animationList[0];
    },
    
    async play() {
      this._isPause = false;
      this._isStop = false;
      this._lastAnimationIndex = 0;
      await this.executeAnimation(this._animationList);    
    },
    
    async executeAnimation(animations) {
      console.log("executeAnimation",animations,this._animationList)
      await asyncForEach(animations, async animation => {
        await this._play(animation);
      });    
    },
    
    async _play(animation) {
      const { duration, easing, type ,direction, properties } = animation;
      // skip animation
      if (!properties || duration === 0) {
        return Promise.resolve();
      }
      if(type=="ease"){

        if(["top","left","right","bottom"]?.includes(direction)){
          switch(direction){
            case "top":
              this.set({
                top : this.top - properties.left
              })
              properties.top =`+=${properties.left}`
              delete properties.left
              break
            case "left":
              this.set({
                left : this.left - properties.left
              })
              properties.left =`+=${properties.left}`
              break
            case "bottom":
              this.set({
                top : this.top + properties.left
              })
              properties.top =`-=${properties.left}`
              delete properties.left
              break
            case "right":
              this.set({
                left : this.left + properties.left
              })
              properties.left =`-=${properties.left}`
              break
            default:
              this.set({
                left : this.left - properties.left
              })
              properties.top =`+=${properties.left}`
              break
          }
        }
        
        return new Promise(resolve => {    	
          this.animate({...properties}, {
            duration,
            easing: fabric.util.ease[easing] ? fabric.util.ease[easing] : newFab.util.ease.linear,
            onChange: this.canvas.renderAll.bind(this.canvas),
            onComplete: () => {        
              if (!this._isPause) {
                this._lastAnimationIndex = this._animationList.findIndex(item => item.id === animation.id);
              }
    
              resolve(true)
            },
            abort: () => {
              return this._isPause || this._isStop;
            }
          });
        })
      }
    },
    
    pause() {   	
      if (this._lastAnimationIndex === this._animationList.length - 1) return;
  
      this._isPause = !this._isPause;
  
      if (this._isPause) {
        this._lastStateAnimationProperties = this.getPropertiesForAnimation();
      } else {
        this.set(this._lastStateAnimationProperties);
        this.setCoords();
        this.canvas.requestRenderAll();
        
        const animations = this._animationList.filter((_, i) => i > this._lastAnimationIndex);
  
        this._lastStateAnimationProperties = null;
        this.executeAnimation(animations);
      }
    },
    
    stop() {
      this._isStop = true;
      const firstAnimation = this.getFirstAnimation();
      this.set(firstAnimation.properties);
      this.setCoords();
      this.canvas.requestRenderAll();
    },
  });

  newFab.FText.fromObject = function(object, callback) {
    const objectCopy = clone(object), path = object.path;
    delete objectCopy.path;
   
    return fabric.Object._fromObject('FText', objectCopy, function(textInstance) {
      textInstance.styles = newFab.util.stylesFromArray(object.styles, object.text);
      if(object?.animations?.length > 0){
        textInstance._animationList = object?.animations
        // textInstance.animations =  object?.animations
        // console.log("object?.animations",object?.animations)
      }
      // textInstance.addAnimation({duration: 1000, easing: "easeInBounce", properties: {left: 50, top: 50}})
  // .addAnimation({duration: 1000, easing: "easeOutBounce", properties: {left: 500, top: 50, fill: "red"}})
  // .addAnimation({duration: 1000, easing: "easeOutBounce", properties: {left: 500, top: 500, fill: "green"}});
      if (path) {
        fabric.Object._fromObject('Path', path, function(pathInstance) {
          textInstance.set('path', pathInstance);
          callback(textInstance);
        }, 'path');
      }
      else {
        callback(textInstance);
      }
    }, 'text');
  };
}