import { useContext, useEffect } from 'react';
import { fabric } from 'fabric';
import { Slider, Form, Row, Col } from 'antd';
import ColorSetter from '../../ColorSetter';
import { GloablStateContext } from '@/context';
import TextShadow from './TextShadow';
import TextPath from './TextPath';
import TextPattern from './TextPattern';
import { drawTextPath, getPathOffset, removeTextPath } from '@/editor/objects/textbox';
import { loadImageDom } from '@/editor/objects/image';
import { transformColors2Fill, transformFill2Colors } from '@/utils';

const { Item: FormItem } = Form;

export default function TextFx () {
  const [form] = Form.useForm();
  const { object, editor } = useContext(GloablStateContext);

  const handleTextPattern = async (pattern) => {
    if (!object) return;
    if (!pattern.enable || !pattern.url) {
      if (object.fill instanceof fabric.Pattern) {
        object.set('fill', '#000000');
      }
      return Promise.resolve();
    }

    try {
      const img = await loadImageDom(pattern.url);
      object.set('fill', new fabric.Pattern({
        source: img as HTMLImageElement,
        repeat: 'repeat'
      }));
    } catch(e) {
      console.log(e);
    }
  }

  const handleStroke = (_stroke) => {
    let stroke = transformColors2Fill(_stroke);
    if (typeof stroke !== 'string') {
      stroke = new fabric.Gradient(stroke);
    }
    object.set('stroke', stroke);
  }

  const handleFxValueChange = async (values) => {
    if (!object || !editor) return;
    const keys = Object.keys(values);
    for (let key of keys) {
      const v = values[key];
      if (key === 'shadow') {
        if (v.enable) {
          // @ts-ignore object shadow
          object.shadow = {
            color: v.color,
            blur: v.blur,
            offsetX: v.offset,
            offsetY: v.offset
          };
        } else {
          object.shadow = undefined;
        }
      } else if (key === 'path') {
        if (v.enable) {
          drawTextPath(object, v.offset);
        } else {
          removeTextPath(object);
        }
      } else if (key === 'pattern') {
        await handleTextPattern(v);
      } else if (key === 'stroke') {
        handleStroke(v);
      } else {
        object.set(key as (keyof fabric.Object), v);
      }
    }
    editor.canvas.requestRenderAll();
    editor.fireCustomModifiedEvent();
  }

  const initObjectFx = () => {
    const fill = object.fill;
    form.setFieldsValue({
      stroke: transformFill2Colors(object.stroke),
      strokeWidth: object.strokeWidth || 0,
      textBackgroundColor: object.textBackgroundColor,
      shadow: {
        enable: !!object.shadow,
        color: object.shadow?.color || object.stroke,
        blur: object.shadow?.blur || 0,
        offset: object.shadow?.offsetX || 0
      },
      path: {
        enable: !!object.path,
        offset: getPathOffset(object)
      },
      pattern: {
        enable: fill instanceof fabric.Pattern,
        url: fill?.source?.src
      }
    });
  }

  useEffect(() => {
    if (object && object.type === 'f-text') {
      initObjectFx();
    }
  }, [object]);

  return (
    <Form
      form={form}
      onValuesChange={handleFxValueChange}
      colon={true}
      
      style={{ 
        marginTop: 24 , 
        color : "var(--text-color)",
        display : "flex",
        flexDirection : "column",
        width : "100%",
        alignItems : "flex-start"
      }}
      className='setter-content-item'
    >
      <Row gutter={24}>
        <Col span={24}>
          <FormItem 
            label={<span style={{ fontSize: 15, fontWeight: 'bold', color : "var(--text-color)" }}>STROKE</span>}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <FormItem label="Color" name="stroke">
            <ColorSetter />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <FormItem label="Thickness " name="strokeWidth">
            <Slider
              min={0}
              max={20}
              style={{width : "100%",minWidth:"150px"}}
              trackStyle={{
                background : "blue"
              }}
              railStyle={{
                background : "#ccc"
              }}
              
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <FormItem name="shadow" style={{ marginBottom: 0 }}>
            <TextShadow />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <FormItem name="path" style={{ marginBottom: 0 }}>
            <TextPath />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <FormItem name="pattern">
            <TextPattern />
          </FormItem>
        </Col>
      </Row>
    </Form>
  )
}