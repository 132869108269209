import { createContext } from 'react';
import { fabric } from 'fabric';
import Editor from '@/editor';

interface CustomFabricObject extends fabric.Object{
  addAnimation?:any
}

export interface IGloablStateContext {
  object?: CustomFabricObject | null | undefined;
  setActiveObject?: (o: fabric.Object) => void;
  currentSider?: string | null | undefined;
  setCurrentSider?: (o: string) => void;
  isReady?: boolean;
  setReady?: (o: boolean) => void;
  editor?: Editor;
  roughSvg?: any;
  currentCreation?: fabric.Object[] | null | undefined;
  setCurrentCreation?: (o: fabric.Object[]) => void;
  currentIndex?: number;
  setCurrentIndex?: (o: number) => void;
}

export const GloablStateContext = createContext<IGloablStateContext>(null);